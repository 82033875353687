/* Configure the application and render the root component */

// Polyfills

import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(localizedFormat);

// Translations

import "./lib/i18n";

// CSS & libraries

import "@/assets/scss/main.scss";
import "react-day-picker/dist/style.css";
import "react-material-symbols/rounded";

import router from "@/Router";
import StripeTestCardsMenu from "@/components/stripe/StripeTestCardsMenu";
import Spinner from "@/components/ui/spinner";
import { Toaster } from "@/components/ui/toaster";
import useOnLogout from "@/lib/hooks/use-on-logout";
import { useValidateAuthTokenQuery } from "@/lib/services/auth.services";
import { removeAuthTokenHeader, setAuthTokenHeader } from "@/lib/services/config/api";
import { keys } from "@/lib/utils/storage.utils";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import branch from "branch-sdk";
import Cookies from "js-cookie";
import { PostHogProvider } from "posthog-js/react";
import { PropsWithChildren, useEffect } from "react";
import ReactDOM from "react-dom/client";
import TagManager from "react-gtm-module";
import { RouterProvider } from "react-router-dom";

/* Initialize the Branch SDK */

branch.init(import.meta.env.VITE_PUBLIC_BRANCH_KEY);

/* Create the react-query client */

const queryClient = new QueryClient();

/* Set the auth token header if the user is already logged in */

const authToken = Cookies.get(keys.AUTH_TOKEN);

if (authToken) {
  setAuthTokenHeader(authToken);
} else {
  removeAuthTokenHeader();
}

// Removes auto-zoom on iPhone

if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
  const viewport = document.querySelector('meta[name="viewport"]');
  viewport?.setAttribute("content", "width=device-width, initial-scale=1, maximum-scale=1");
}

function AuthValidation({ children }: PropsWithChildren) {
  const logout = useOnLogout();
  const validateAuthTokenQuery = useValidateAuthTokenQuery(typeof authToken === "string");

  useEffect(() => {
    if (validateAuthTokenQuery.isLoading) return;
    if (validateAuthTokenQuery.error) logout();
  }, [validateAuthTokenQuery]);

  if (validateAuthTokenQuery.isLoading) {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spinner />
      </div>
    );
  }

  return children;
}

TagManager.initialize({ gtmId: import.meta.env.VITE_GTM_ID });

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <PostHogProvider
    apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY}
    options={{ api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST }}
  >
    <QueryClientProvider client={queryClient}>
      <AuthValidation>
        <RouterProvider router={router} />
      </AuthValidation>
      <Toaster />
      <StripeTestCardsMenu />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </PostHogProvider>
);
