import { clicknpark } from "@/lib/services/config/api";
import {
  CPException,
  CheckoutDetailsResponse,
  GetCheckoutDetailsRequest,
  PayResponse,
} from "@clicknpark/sdk";
import { useMutation, useQuery } from "@tanstack/react-query";

// Get Checkout Details (GET request)
// ========================================

export const GET_CHECKOUT_DETAILS_QUERY_KEY = "get-checkout-details";

type GetCheckoutDetailsData = {
  request: GetCheckoutDetailsRequest;
  enabled?: boolean;
};

export const getCheckoutDetails = async (data: GetCheckoutDetailsData): Promise<CheckoutDetailsResponse> => {
  return await clicknpark.checkout.getCheckoutDetails(data.request);
};

export const useGetCheckoutDetailsQuery = (data: GetCheckoutDetailsData) => {
  const enabled = typeof data?.enabled === "boolean" ? data.enabled : true;

  return useQuery<CheckoutDetailsResponse, CPException, CheckoutDetailsResponse>({
    queryKey: [GET_CHECKOUT_DETAILS_QUERY_KEY, data.request],
    queryFn: () => getCheckoutDetails(data),
    enabled,
    refetchOnWindowFocus: false,
    retry: false,
  });
};

// Payment

export const PAY_MUTATION_KEY = "pay";

export const pay = async (reservationId: string) => {
  return await clicknpark.checkout.pay(reservationId);
};

export const usePayMutation = () => {
  return useMutation<PayResponse, CPException, string>({
    mutationKey: [PAY_MUTATION_KEY],
    mutationFn: pay,
  });
};