import { AiFillFacebook } from "react-icons/ai";
import { twMerge } from "tailwind-merge";

interface Props {
  className?: string;
}

export default function SocialLoginButtonFacebook({ className }: Props) {
  return (
    <div
      className={twMerge(
        "md:p-1 p-2 rounded-[5px] bg-[#3b5998] cursor-pointer hover:bg-[#2f477a] transition-colors hover:shadow-lg",
        className
      )}
    >
      <AiFillFacebook className="text-white" />
      <span className="sr-only">Facebook</span>
    </div>
  );
}
