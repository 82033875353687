import { MaterialSymbol } from "react-material-symbols";

interface Props {
  number: number;
  completed?: boolean;
}

export default function StepNumber({ number, completed }: Props) {
  return (
    <div className="flex items-center justify-center md:size-[28px] size-[24px] rounded-full bg-simple-900 mb-0.5">
      <strong className="font-semibold text-sm text-white flex items-center">
        {completed ? <MaterialSymbol icon="check" size={20} fill /> : number}
      </strong>
    </div>
  );
}
