import CheckoutContact from "@/components/checkout/Contact";
import CheckoutDuration from "@/components/checkout/Duration";
import CheckoutPayment from "@/components/checkout/Payment";
import CheckoutSummary from "@/components/checkout/Summary";
import Wrapper from "@/components/layout/Wrapper";
import CheckoutShell from "@/components/layout/shell/checkout/Shell";
import { Button } from "@/components/ui/button";
import Error from "@/components/ui/error";
import { useLanguageUtils } from "@/lib/hooks/use-language-utils";
import { TrackingServices } from "@/lib/services/tracking.services";
import { useCheckoutStore } from "@/lib/store/checkout.store";
import { StorageUtils, keys } from "@/lib/utils/storage.utils";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export default function Checkout() {
  const { t } = useTranslation("common");
  const { language } = useLanguageUtils();
  const [searchParams] = useSearchParams();
  const { parkIds, setStart, setEnd, setParkIds, prevStep, setStep } =
    useCheckoutStore();

  const pid = searchParams.get("pid");
  const start = searchParams.get("start");
  const end = searchParams.get("end");

  useEffect(() => {
    if (!pid || !start || !end) return;
    setStart(new Date(start));
    setEnd(new Date(end));
    setParkIds([pid]);
  }, [pid, start, end]);

  if (!pid || !start || !end) {
    return (
      <div className="h-screen flex flex-col items-center justify-center text-md text-red-600 p-10 text-center">
        <Error title={t("invalidCheckout")} />
        <Button className="mt-5">
          <a href="https://clicknpark.com">{t("goBackToClicknpark")}</a>
        </Button>
      </div>
    );
  }

  return (
    <CheckoutShell>
      <Wrapper>
        <div className="flex flex-col md:mt-8 md:justify-between md:flex-row md:h-auto h-full">
          <div className="md:w-3/5 md:pr-5 md:order-1 order-2">
            <div className="space-y-6">
              <CheckoutDuration
                onBack={() => {
                  // Since this is not a "real" step, we need to reset the step to 2
                  setStep(2); // This is a workaround to avoid the contact form to be skipped
                  TrackingServices.trackCheckoutMoveBackToDuration();
                  StorageUtils.removeSessionItem(keys.SAVED_PAYMENT_INTENT);
                  StorageUtils.removeSessionItem(keys.SAVED_PAYMENT_INTENT_EXPIRES_AT);
                  StorageUtils.removeSessionItem(keys.SAVED_RESERVATION_ID);
                  window.location.href = `${import.meta.env.VITE_CLICKNPARK_WEB_APP_URL}/park/${parkIds[0]}?lang=${language}`;
                }}
              />

              <CheckoutContact
                onBack={() => {
                  TrackingServices.trackCheckoutMoveBackToDuration();
                  window.location.href = `${import.meta.env.VITE_CLICKNPARK_WEB_APP_URL}/park/${parkIds[0]}?lang=${language}`;
                }}
              />

              <CheckoutPayment
                {...{
                  onBack: () => {
                    TrackingServices.trackCheckoutMoveBackToContact();
                    prevStep();
                  },
                  onDatesReset: () => {
                    setStep(2); // This is a workaround to avoid the contact form to be skipped
                    TrackingServices.trackCheckoutMoveBackToDuration();
                    window.location.href = `${import.meta.env.VITE_CLICKNPARK_WEB_APP_URL}/park/${parkIds[0]}?lang=${language}`;
                  },
                }}
              />
            </div>
          </div>
          <div className="md:w-2/5 md:mb-0 mb-4 border-silver-800 md:order-2 order-1">
            <CheckoutSummary />
          </div>
        </div>
      </Wrapper>
    </CheckoutShell>
  );
}
