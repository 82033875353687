import { useFeatureFlagEnabled } from "posthog-js/react";

import { useToast } from "@/lib/hooks/use-toast";
import { useState } from "react";
import { MaterialSymbol } from "react-material-symbols";

const STRIPE_TEST_CARDS = [
  { name: "Succès", number: "4242424242424242" },
  { name: "Refus de paiement générique", number: "4000000000000002" },
  {
    name: "Refus de paiement pour cause de fonds insuffisants",
    number: "4000000000009995",
  },
  { name: "Carte Perdue", number: "4000000000009987" },
  { name: "Carte Volée", number: "4000000000009979" },
  { name: "Carte Expirée", number: "4000000000000069" },
  { name: "CVC Incorrect", number: "4000000000000127" },
  { name: "Erreur de Traitement", number: "4000000000000119" },
  { name: "Numéro Incorrect", number: "4242424242424241" },
  { name: "Dépassement de la Vitesse de la Carte", number: "4000000000006975" },
];

function StripeTestCardsMenu() {
  const { toast } = useToast();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const flagEnabled = useFeatureFlagEnabled("enable-stripe-cards-devtool");

  const handleCardClick = (cardNumber: string) => {
    navigator.clipboard.writeText(cardNumber);
    toast({ title: "Copié dans le presse-papiers", description: cardNumber });
    setIsMenuOpen(false);
  };

  const isActive = import.meta.env.MODE === "development" || flagEnabled;

  if (!isActive) return null;

  return (
    <div className="fixed bottom-5 left-5 z-1000">
      <button
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        className="bg-blue-600 border border-gray-300 shadow-sm rounded-full w-12 h-12 relative z-10 flex flex-col items-center justify-center cursor-pointer transition-all ease-in-out duration-150 hover:bg-blue-700 hover:shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        <MaterialSymbol icon="credit_card" color="#FFF" />
        <span className="w-full text-white text-[8px] uppercase font-bold">Stripe</span>
      </button>

      {isMenuOpen && (
        <ul className="fixed bottom-20 left-3 z-10 bg-white p-2 border-4 border-rapide-600 shadow-sm rounded-xl divide-y h-[300px] overflow-auto">
          {STRIPE_TEST_CARDS.map((card) => (
            <li
              className="list-none text-sm hover:bg-silver-800 rounded-md p-2 cursor-pointer transition-all ease-in-out duration-150"
              key={card.name}
            >
              <button onClick={() => handleCardClick(card.number)}>
                <div className="text-left text-rapide-600 font-bold text-[10px] uppercase">{card.name}</div>
                <div className="text-left font-medium text-sm">{card.number}</div>
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default StripeTestCardsMenu;
