import { v4 as uuidv4 } from "uuid";
import UtmUtils from "@/lib/utils/utm.utils";
import { StorageUtils, keys } from "@/lib/utils/storage.utils";

export default function getMetadata(): { [key: string]: string | undefined } {
  const utms = UtmUtils.getSavedUtmParams();

  const searchSessionId =
    StorageUtils.getSessionItem(keys.SESSION_ID) || uuidv4();

  return {
    searchSessionId,
    utm_source: utms?.source,
    utm_medium: utms?.medium,
    utm_campaign: utms?.campaign,
    utm_content: utms?.content,
    utm_term: utms?.term,
    platform: "web",
    hostname: window.location.hostname,
    browser: navigator.userAgent,
  };
}
