import cn from "@/lib/utils/cn.utils";
import { useTranslation } from "react-i18next";
import { MaterialSymbol } from "react-material-symbols";

interface Props {
  title?: string;
  description?: string;
  withIcon?: boolean;
  size?: "sm" | "md" | "lg";
  align?: "left" | "center" | "right";
}

export default function IError({ title, description, withIcon = false, size = "sm", align = "left" }: Props) {
  const { t } = useTranslation("common");

  if (!title && !description) return null;

  return (
    <div
      className={cn(
        "flex flex-col items-center justify-center h-full border border-red-500 mb-4",
        size === "sm" ? "p-3 rounded-md" : "",
        size === "md" ? "p-5 rounded-lg" : "",
        size === "lg" ? "p-8 rounded-lg" : "",
        align === "left" ? "items-start" : "",
        align === "center" ? "items-center" : "",
        align === "right" ? "items-end" : ""
      )}
    >
      {withIcon ? (
        <MaterialSymbol icon="error_circle_rounded" size={size === "sm" ? 24 : size === "md" ? 32 : 40} className="mb-3 text-red-600" />
      ) : null}

      <div
        className={cn(
          "font-semibold text-red-600 text-center",
          size === "sm" ? "text-sm" : "",
          size === "md" ? "text-lg" : "",
          size === "lg" ? "text-xl" : "",
          align === "left" ? "text-left" : "",
          align === "center" ? "text-center" : "",
          align === "right" ? "text-right" : ""
        )}
      >
        {title ?? t("unexpectedError")}
      </div>

      {description ? (
        <div
          className={cn(
            "mt-2 font-medium text-black text-center",
            size === "sm" ? "text-sm" : "",
            size === "md" ? "text-md" : "",
            size === "lg" ? "text-lg" : "",
            align === "left" ? "text-left" : "",
            align === "center" ? "text-center" : "",
            align === "right" ? "text-right" : ""
          )}
        >
          {description}
        </div>
      ) : null}
    </div>
  );
}
