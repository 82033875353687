import { Button } from "@/components/ui/button";
import { usePayMutation } from "@/lib/services/checkout.services";
import { deleteSavedPaymentIntent } from "@/lib/services/reservations.services";
import { useCheckoutStore } from "@/lib/store/checkout.store";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  onBack: () => void;
  reservationId?: string;
}

export default function FormNoPayment({ onBack, reservationId }: Props) {
  const { t } = useTranslation("payment");
  const payMutation = usePayMutation();
  const [isLoading, setIsLoading] = useState(false);
  const { returnUrl } = useCheckoutStore();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    if (!reservationId) return;
    e.preventDefault();
    setIsLoading(true);
    await payMutation.mutateAsync(reservationId);
    deleteSavedPaymentIntent();
    if (returnUrl) window.location.replace(returnUrl);
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <p>{t("paidInCredits")}</p>

      <div className="mt-4 flex space-x-4">
        <Button color="secondary" onClick={onBack} disabled={isLoading} type="button">
          {t("back")}
        </Button>
        <Button className="flex-1" loading={isLoading} disabled={isLoading} type="submit">
          {t("confirmCreditsPayment")}
        </Button>
      </div>
    </form>
  );
}
