import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useToast } from "@/lib/hooks/use-toast";
import { GET_CHECKOUT_DETAILS_QUERY_KEY } from "@/lib/services/checkout.services";
import { handleError } from "@/lib/services/helpers/clicknpark-errors.helpers";
import { useRedeemPromoCodeMutation } from "@/lib/services/promo-code.services";
import { keys } from "@/lib/utils/storage.utils";
import { useQueryClient } from "@tanstack/react-query";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useTranslation } from "react-i18next";

interface Props {}

export default function FormPromoCode({}: Props) {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["summary", "validation", "common"]);
  const [_, savePromoCode] = useLocalStorage<string>(keys.ACTIVE_PROMO_CODE, undefined);
  const { toast } = useToast();

  // Mutations
  const redeemPromoCodeMutation = useRedeemPromoCodeMutation();

  // Form
  // =============================
  const formSchema = z.object({
    code: z.string().min(1, { message: t("validation:required") }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      code: "",
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      await redeemPromoCodeMutation.mutateAsync(values.code);
      savePromoCode(values.code);
      queryClient.invalidateQueries({ queryKey: [GET_CHECKOUT_DETAILS_QUERY_KEY] });
      form.reset();
    } catch (error) {
      const handledError = handleError(error);
      if (handledError.handled) toast({ ...handledError.formatted, variant: "destructive" });
      else toast({ title: handledError.code, description: handledError.message, variant: "destructive" });
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Label htmlFor="addPromoCode">{t("summary:addPromoCode")}</Label>

        <div className="flex space-x-2">
          <FormField
            name="code"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input {...field} value={field.value} id="addPromoCode" />
                </FormControl>
              </FormItem>
            )}
          />

          <Button size="sm" type="submit" loading={form.formState.isSubmitting} disabled={form.formState.isSubmitting}>
            {t("summary:add")}
          </Button>
        </div>
      </form>
    </Form>
  );
}
