import StepNumber from "@/components/checkout/StepNumber";
import FormAuthHandler from "@/components/forms/FormAuthHandler";
import FormContact from "@/components/forms/FormContact";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import Heading from "@/components/ui/heading";
import { Separator } from "@/components/ui/separator";
import Spinner from "@/components/ui/spinner";
import { cookieOptions } from "@/lib/configs/cookie.config";
import useOnLogout from "@/lib/hooks/use-on-logout";
import { setAuthTokenHeader } from "@/lib/services/config/api";
import { deleteSavedPaymentIntent } from "@/lib/services/reservations.services";
import { TrackingServices } from "@/lib/services/tracking.services";
import { GET_USER_QUERY_KEY, GET_USER_SETTINGS_QUERY_KEY, useGetUserQuery } from "@/lib/services/user.services";
import { GET_VEHICLES_QUERY_KEY } from "@/lib/services/vehicles.services";
import { useCheckoutStore } from "@/lib/store/checkout.store";
import { keys } from "@/lib/utils/storage.utils";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

type Form = "auth" | "contact";

interface Props {
  onBack: () => void;
}

export default function CheckoutContact({ onBack }: Props) {
  const logout = useOnLogout();
  const { t } = useTranslation("auth");
  const queryClient = useQueryClient();
  const [cookie, setCookie] = useCookies([keys.AUTH_TOKEN]);
  const authToken = cookie[keys.AUTH_TOKEN];

  const [form, setForm] = useState<Form>("auth");
  const { step, nextStep, prevStep } = useCheckoutStore();
  const isActiveStep = step === 2;

  const userQuery = useGetUserQuery({ enabled: !!authToken });
  const user = userQuery.data;

  useEffect(() => {
    if (user) {
      TrackingServices.setUserId(user);
      setForm("contact");
    }
  }, [user]);

  const onAuth = (authToken?: string) => {
    /*
      if the user is already logged in,
      we don't need to set the authToken
    */

    if (authToken) {
      setCookie(keys.AUTH_TOKEN, authToken, cookieOptions);
      setAuthTokenHeader(authToken);
    }

    setForm("contact");
  };

  const onGuest = () => {
    /*
      User does not have an account, we need to move to the next step where
      we'll ask for the contact information and TOS acceptance
    */

    setForm("contact");
  };

  const onLogout = () => {
    logout();
    TrackingServices.trackLogout();
    TrackingServices.resetUserId();
    if (!isActiveStep) prevStep();
    queryClient.resetQueries({ queryKey: [GET_USER_QUERY_KEY], exact: true });
    queryClient.resetQueries({
      queryKey: [GET_USER_SETTINGS_QUERY_KEY],
      exact: true,
    });
    queryClient.resetQueries({
      queryKey: [GET_VEHICLES_QUERY_KEY],
      exact: true,
    });
    deleteSavedPaymentIntent();
    setForm("auth");
  };

  return (
    <Card>
      <div className="flex">
        <StepNumber number={2} completed={!isActiveStep} />
        <Heading level="h2" size="sm" className="ml-3" color="simple">
          {t("contactInformation")}
        </Heading>
      </div>

      {user ? (
        <>
          <div className="flex items-end justify-between gap-2 mt-3">
            <div className="text-sm md:text-base font-semibold">
              <div className="mb-0.5 text-xs text-slate-600">{t("loggedInAs")}</div>
              {user.email}
            </div>
            <Button variant="outline" size="sm" onClick={onLogout}>
              {t("logout")}
            </Button>
          </div>
        </>
      ) : null}

      {isActiveStep ? (
        <>
          <Separator className="mb-5 mt-5 md:mt-3" />

          {authToken && userQuery.isLoading ? (
            <div className="flex h-[100px] items-center justify-center">
              <Spinner />
            </div>
          ) : form === "auth" ? (
            <FormAuthHandler
              {...{
                onBack,
                onAuth,
                onGuest,
              }}
            />
          ) : (
            <FormContact
              {...{
                onBack,
                onAuth,
                onContinue: () => {
                  TrackingServices.trackCheckoutMoveToPayment();
                  nextStep();
                },
              }}
            />
          )}
        </>
      ) : null}
    </Card>
  );
}
