import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";

import { Alert, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import Spinner from "@/components/ui/spinner";
import { useGetPaymentMethodsQuery } from "@/lib/services/payment-methods.services";
import { deleteSavedPaymentIntent } from "@/lib/services/reservations.services";
import { useCheckoutStore } from "@/lib/store/checkout.store";
import cn from "@/lib/utils/cn.utils";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  clientSecret: string;
  onBack: () => void;
}

export default function FormPayment({ clientSecret, onBack }: Props) {
  const { t } = useTranslation("payment");

  const stripe = useStripe();
  const elements = useElements();
  const { returnUrl } = useCheckoutStore();

  const [error, setError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [elementsLoaded, setElementsLoaded] = useState(false);
  const [selectedSavedCard, setSelectedSavedCard] = useState<string | undefined>();

  const getPaymentMethodsQuery = useGetPaymentMethodsQuery({
    enabled: true,
  });

  const savedCards = getPaymentMethodsQuery?.data?.savedCards;
  const hasSavedCards = savedCards && savedCards.length > 0;
  const defaultSavedCard = savedCards?.find((card) => card.default) ?? savedCards?.[0];

  useEffect(() => {
    const defaultCard = savedCards?.find((card) => card.default);
    if (defaultCard) setSelectedSavedCard(defaultCard.id);
    else if (savedCards?.[0]) setSelectedSavedCard(savedCards[0].id);
  }, [savedCards]);

  const handleStripeError = (error: any) => {
    if (i18next.exists(`errors:${error.code}`)) {
      setError(t(`errors:${error.code}.title`));
    } else if (error.type === "card_error" || error.type === "validation_error") {
      error.message && setError(error.message);
    } else {
      setError(t("genericError"));
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) return;

    setIsLoading(true);

    if (selectedSavedCard) {
      const { error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: selectedSavedCard,
        return_url: returnUrl,
      });

      if (error) handleStripeError(error);
      if (returnUrl) {
        deleteSavedPaymentIntent();
        window.location.replace(returnUrl);
      }

      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        payment_method: selectedSavedCard || "card",
        return_url: returnUrl || "",
      },
    });

    if (error) {
      handleStripeError(error);
    }

    deleteSavedPaymentIntent();

    setIsLoading(false);
  };

  const PaymentElementComponent = (
    <PaymentElement
      onLoaderStart={() => setElementsLoaded(true)}
      className="min-h-[100px]"
      id="payment-element"
      options={{
        layout: "auto",
        wallets: {
          applePay: "auto",
          googlePay: "auto",
        },
      }}
    />
  );

  return (
    <div className="min-h-[100px]">
      {getPaymentMethodsQuery?.isLoading ? (
        <div className="relative z-0 flex h-[100px] items-center justify-center">
          <Spinner />
        </div>
      ) : !hasSavedCards && !elementsLoaded ? (
        <div className="relative z-0 flex h-[100px] items-center justify-center">
          <Spinner />
        </div>
      ) : null}

      <form id="payment-form" onSubmit={handleSubmit} className={cn("relative z-10", isLoading ? "pointer-events-none opacity-50" : "")}>
        {error ? (
          <Alert variant="error" className="mb-5">
            <AlertTitle>{error}</AlertTitle>
          </Alert>
        ) : null}

        {!getPaymentMethodsQuery?.isPending && (
          <>
            {hasSavedCards ? (
              <Accordion type="single" collapsible defaultValue="savedCards">
                <AccordionItem value="savedCards">
                  <AccordionTrigger>{t("useSavedCards", { count: savedCards.length })}</AccordionTrigger>
                  <AccordionContent>
                    {savedCards && savedCards?.length > 0 ? (
                      <RadioGroup defaultValue={defaultSavedCard?.id} key={defaultSavedCard?.id}>
                        {savedCards.map((card) => (
                          <div className="flex items-center space-x-2" key={card.id}>
                            <RadioGroupItem value={card.id} id={card.id} />
                            <Label htmlFor="option-one" className="flex items-center">
                              <img className="rounded-sm mr-2" src={card.brand.assets.png.small} width={66 / 2.5} height={46 / 2.5} />
                              <span>•••• {card.last4}</span>
                            </Label>
                          </div>
                        ))}
                      </RadioGroup>
                    ) : null}
                  </AccordionContent>
                </AccordionItem>
                <AccordionItem value="newCard">
                  <AccordionTrigger>{t("useNewCard")}</AccordionTrigger>
                  <AccordionContent>
                    <div className="p-1">{PaymentElementComponent}</div>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            ) : (
              PaymentElementComponent
            )}
          </>
        )}

        {savedCards ?? elementsLoaded ? (
          <div className="mt-4 flex space-x-4">
            <Button color="secondary" onClick={onBack} disabled={isLoading} type="button">
              {t("back")}
            </Button>
            <Button className="flex-1" loading={isLoading} disabled={isLoading} type="submit">
              {t("pay")}
            </Button>
          </div>
        ) : null}
      </form>
    </div>
  );
}
