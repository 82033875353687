import { clicknpark } from "@/lib/services/config/api";
import { StorageUtils, keys } from "@/lib/utils/storage.utils";
import {
  CPException,
  CPReservation,
  ReservationCreateRequest,
  ReservationCreateResponse,
} from "@clicknpark/sdk";
import { QueryKey, useMutation, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

// Helpers to validate existing payment intent
// ============================================

export const getSavedPaymentIntent = ():
  | {
      paymentIntent: string;
      expiresAt: string;
      reservationId: string;
    }
  | undefined => {
  const r = StorageUtils.getSessionItem(keys.SAVED_RESERVATION_ID);
  const s = StorageUtils.getSessionItem(keys.SAVED_PAYMENT_INTENT);
  const e = StorageUtils.getSessionItem(keys.SAVED_PAYMENT_INTENT_EXPIRES_AT);

  if (s && e && r) {
    return {
      paymentIntent: s,
      expiresAt: e,
      reservationId: r,
    };
  } else {
    return undefined;
  }
};

export const hasSavedPaymentIntent = (): boolean => {
  return typeof getSavedPaymentIntent() === "undefined" ? false : true;
};

export const isSavedPaymentIntentExpired = (): boolean => {
  const e = StorageUtils.getSessionItem(keys.SAVED_PAYMENT_INTENT_EXPIRES_AT);
  return e && dayjs(e).isSameOrBefore(dayjs()) ? true : false;
};

export const saveReservationId = (reservationId: string) => {
  StorageUtils.setSessionItem(keys.SAVED_RESERVATION_ID, reservationId);
};

export const savePaymentIntent = (paymentIntent: string) => {
  StorageUtils.setSessionItem(keys.SAVED_PAYMENT_INTENT, paymentIntent);
  StorageUtils.setSessionItem(
    keys.SAVED_PAYMENT_INTENT_EXPIRES_AT,
    dayjs().add(5, "minutes").toISOString()
  );
};

export const deleteSavedPaymentIntent = () => {
  StorageUtils.removeSessionItem(keys.SAVED_PAYMENT_INTENT);
  StorageUtils.removeSessionItem(keys.SAVED_PAYMENT_INTENT_EXPIRES_AT);
  StorageUtils.removeSessionItem(keys.SAVED_RESERVATION_ID);
};

// 📝 Create reservation (POST request)
// ========================================

export const CREATE_RESERVATION_MUTATION_KEY = "create-reservation";

export const createReservation = async (
  request: ReservationCreateRequest
): Promise<ReservationCreateResponse> => {
  return await clicknpark.reservations.create(request);
};

export const useCreateReservationMutation = () => {
  return useMutation<
    ReservationCreateResponse,
    CPException,
    ReservationCreateRequest
  >({
    mutationKey: [CREATE_RESERVATION_MUTATION_KEY],
    mutationFn: createReservation,
  });
};

// Attach vehicle to reservation (POST request)
export const ATTACH_VEHICLE_TO_RESERVATION_MUTATION_KEY =
  "attach-vehicle-to-reservation";

export interface AttachVehicleToReservationMutationData {
  reservationId: string;
  vehicleId: string;
}

export const attachVehicleToReservation = async ({
  reservationId,
  vehicleId,
}: AttachVehicleToReservationMutationData) => {
  return await clicknpark.reservations.attachVehicle(reservationId, vehicleId);
};

export const useAttachVehicleToReservationMutation = () => {
  return useMutation<
    AttachVehicleToReservationMutationData,
    CPException,
    AttachVehicleToReservationMutationData
  >({
    mutationKey: [ATTACH_VEHICLE_TO_RESERVATION_MUTATION_KEY],
    mutationFn: (data: AttachVehicleToReservationMutationData) =>
      attachVehicleToReservation(data),
  });
};

// 📝 Get reservation by ID (GET request)

export const GET_RESERVATION_QUERY_KEY = "reservation";

export const getReservation = async (id?: string): Promise<CPReservation> => {
  if (!id) throw new Error("Reservation ID is required");
  return await clicknpark.reservations.getById(id);
};

export const useGetReservationQuery = (
  id?: string,
  queryOptions?: { enabled?: boolean }
) => {
  const enabled =
    typeof queryOptions?.enabled === "boolean" ? queryOptions.enabled : true;

  return useQuery<CPReservation, CPException, CPReservation, QueryKey>({
    queryKey: [GET_RESERVATION_QUERY_KEY, id],
    queryFn: () => getReservation(id),
    staleTime: 1000 * 60 * 1, // 1 minute
    enabled,
  });
};

