import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import SingleSignOn from "@/components/forms/SingleSignOn";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useAuthPreflightMutation } from "@/lib/services/auth.services";
import { HandledError, handleError } from "@/lib/services/helpers/clicknpark-errors.helpers";
import { useTranslation } from "react-i18next";

interface Props {
  onPreflight: (email: string, hasAccount: boolean, hasCompletedSignup: boolean) => void;
  onSingleSignOn: (authToken: string) => void;
  onError: (error: HandledError) => void;
}

const formSchema = z.object({
  email: z.string().email(),
});

export default function FormAuthPreflight({ onPreflight, onSingleSignOn, onError }: Props) {
  const { t } = useTranslation("auth");

  const preflight = useAuthPreflightMutation();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  });

  async function submitHandler(values: z.infer<typeof formSchema>) {
    const { email } = values;

    try {
      const data = await preflight.mutateAsync({ email });
      onPreflight(email, data.hasAccount, data.hasCompletedSignUp);
    } catch (error) {
      onError(handleError(error));
    }
  }

  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(submitHandler)} className="space-y-6">
          <FormField
            name="email"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("email")}</FormLabel>
                <FormControl>
                  <Input autoFocus {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="md:flex items-center justify-between md:space-x-2 md:space-y-0 space-y-6">
            <Button type="submit" className="md:w-auto w-full" loading={form.formState.isSubmitting} disabled={form.formState.isSubmitting}>
              {t("continueWithEmail")}
            </Button>

            <SingleSignOn onSuccess={onSingleSignOn} />
          </div>
        </form>
      </Form>
    </div>
  );
}
