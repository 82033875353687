import FormPromoCode from "@/components/forms/FormPromoCode";
import IconChevron from "@/components/icons/Chevron";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import Heading from "@/components/ui/heading";
import { Label } from "@/components/ui/label";
import { Separator } from "@/components/ui/separator";
import { Skeleton } from "@/components/ui/skeleton";
import { useLanguageUtils } from "@/lib/hooks/use-language-utils";
import { useToast } from "@/lib/hooks/use-toast";
import { useGetCheckoutDetailsQuery } from "@/lib/services/checkout.services";
import { handleError } from "@/lib/services/helpers/clicknpark-errors.helpers";
import { useGetParkQuery } from "@/lib/services/parks.services";
import { TrackingServices } from "@/lib/services/tracking.services";
import { useGetUserQuery } from "@/lib/services/user.services";
import { useCheckoutStore } from "@/lib/store/checkout.store";
import cn from "@/lib/utils/cn.utils";
import { DateUtils } from "@/lib/utils/date.utils";
import { keys } from "@/lib/utils/storage.utils";
import { CPCheckoutItem, CPCheckoutTaxItem } from "@clicknpark/sdk";
import { useLocalStorage } from "@uidotdev/usehooks";
import { TFunction } from "i18next";
import { X } from "lucide-react";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { MaterialSymbol } from "react-material-symbols";

const getTranslationForLabel = (label: string, t: TFunction<"summary", undefined>) => {
  switch (label) {
    case "reservation":
      return t("reservation");
    case "accountCredit":
      return t("accountCredit");
    case "paymentProcessingFee":
      return t("paymentProcessingFee");
    case "dayRateMode":
      return t("dayRateMode");
    case "eveningRateMode":
      return t("eveningRateMode");
    case "eventRateMode":
      return t("eventRateMode");
    case "promoCode":
      return t("promoCode");
  }

  return label;
};

const isTaxItem = (item: CPCheckoutItem | CPCheckoutTaxItem) => {
  switch (item.label) {
    case "TPS":
      return true;
    case "TVQ":
      return true;
    case "HST":
    default:
      return false;
  }
};

const SummaryLabel = ({
  children,
  bold,
  small,
  mode,
  className,
}: {
  children: React.ReactNode;
  bold?: boolean;
  small?: boolean;
  mode?: "add" | "substract";
  className?: string;
}) => (
  <strong
    className={cn(
      mode === "substract" ? "text-green-500" : "text-black",
      bold ? "font-semibold" : "font-medium",
      small ? "text-xs" : "text-sm",
      className
    )}
  >
    {mode === "substract" ? "-" : ""} {children}
  </strong>
);

export default function CheckoutSummary() {
  const { toast } = useToast();
  const { t } = useTranslation("summary");
  const { language } = useLanguageUtils();
  const [cookie] = useCookies([keys.AUTH_TOKEN]);
  const authToken = cookie[keys.AUTH_TOKEN];
  const { parkIds, getStart, getEnd, setPaymentRequired } = useCheckoutStore();
  const [promoCode, setPromoCode] = useLocalStorage<string | undefined>(keys.ACTIVE_PROMO_CODE, undefined);

  const hasParkId = !!parkIds?.[0];
  const checkoutDetailsQuery = useGetCheckoutDetailsQuery({
    request: {
      parkingId: parkIds[0],
      startTime: getStart().toISOString(),
      endTime: getEnd().toISOString(),
      locale: `${language}-CA`,
      promoCode,
    },
    enabled: hasParkId,
  });

  const getParkQuery = useGetParkQuery(parkIds[0], { enabled: hasParkId });
  const userQuery = useGetUserQuery({ enabled: !!authToken });
  const user = userQuery.data;

  const [summaryOpen, setSummaryOpen] = useState(false);
  const [combinedItems, setCombinedItems] = useState<(CPCheckoutItem | CPCheckoutTaxItem)[]>([]);

  useEffect(() => {
    checkoutDetailsQuery?.data?.payable !== undefined && setPaymentRequired(checkoutDetailsQuery.data.payable > 0);
  }, [checkoutDetailsQuery?.data?.payable, setPaymentRequired]);

  useEffect(() => {
    if (checkoutDetailsQuery.error) {
      const handledError = handleError(checkoutDetailsQuery.error);
      if (handledError.handled) toast({ ...handledError.formatted, variant: "destructive" });
      else toast({ title: handledError.code, description: handledError.message, variant: "destructive" });
      setPromoCode(undefined);
    }
  }, [checkoutDetailsQuery.error]);

  useEffect(() => {
    if (checkoutDetailsQuery.data) {
      let items = [...checkoutDetailsQuery.data.checkoutItems, ...checkoutDetailsQuery.data.taxes];
      const itemLabel = "paymentProcessingFee";
      const itemToMove = items.filter((i) => i.label === itemLabel)[0];
      const reorderedItems = items.filter((i) => i.label !== itemLabel);
      if (itemToMove) reorderedItems.push(itemToMove);
      setCombinedItems(reorderedItems);
    }
  }, [checkoutDetailsQuery.data]);

  useEffect(() => {
    if (checkoutDetailsQuery.data && getParkQuery.data && user) {
      TrackingServices.trackParkBeginCheckout(checkoutDetailsQuery.data.payable, "CAD", getParkQuery.data);
    }
  }, [checkoutDetailsQuery.data, getParkQuery.data, user]);

  return (
    <>
      <Card>
        <Heading color="simple" className="mb-3 hidden md:block">
          {t("summary")}
        </Heading>

        <Heading
          level="h4"
          color="simple"
          className={cn("flex cursor-pointer justify-between md:hidden md:cursor-auto", summaryOpen ? "mb-3 md:mb-3" : "mb-0 md:mb-3")}
          onClick={() => setSummaryOpen(!summaryOpen)}
        >
          <div className="flex items-center">
            {t("viewTheSummary")}
            <IconChevron direction={summaryOpen ? "up" : "down"} className="ml-1" />
          </div>
          <span>{checkoutDetailsQuery.data?.formattedPayable}</span>
        </Heading>

        <div className={cn(summaryOpen ? "block md:block" : "hidden md:block")}>
          {checkoutDetailsQuery?.isPending ? (
            <div className="mt-5">
              <div className="space-y-3">
                <Skeleton className="w-3/4 h-[10px] rounded-full" />
                <Skeleton className="w-3/4 h-[10px] rounded-full" />
              </div>
              <Skeleton className="my-5 w-full h-[1px] rounded-full" />
              <div className="space-y-3">
                <Skeleton className="w-3/4 h-[10px] rounded-full" />
                <Skeleton className="w-full h-[10px] rounded-full" />
                <Skeleton className="w-full h-[10px] rounded-full" />
                <Skeleton className="w-full h-[10px] rounded-full" />
              </div>
              <Skeleton className="my-5 w-full h-[1px] rounded-full" />
              <div className="space-y-3">
                <Skeleton className="w-3/4 h-[10px] rounded-full" />
              </div>
            </div>
          ) : (
            <>
              <ul>
                <li>
                  <SummaryLabel bold>{getParkQuery.data?.address.line1}</SummaryLabel>
                  <div className="flex items-center space-x-1">
                    <SummaryLabel>{DateUtils.formatDate(getStart())}</SummaryLabel>
                    <MaterialSymbol icon="arrow_forward" />
                    <SummaryLabel>{DateUtils.formatDate(getEnd())}</SummaryLabel>
                  </div>
                </li>
              </ul>

              <Separator className="md:y-5 my-4" />

              <ul className="space-y-1">
                {combinedItems.map((item) => {
                  if (isTaxItem(item)) {
                    return (
                      <li className="flex items-center justify-between" key={item.label}>
                        <SummaryLabel bold small className="pl-2 opacity-50">
                          {t(item.label)}
                        </SummaryLabel>
                        <SummaryLabel small bold className="opacity-50">
                          {item.formattedValue}
                        </SummaryLabel>
                      </li>
                    );
                  }

                  return (
                    <li className="flex items-center justify-between" key={item.label}>
                      <SummaryLabel bold>{getTranslationForLabel(item.label, t)}</SummaryLabel>
                      <SummaryLabel bold mode={(item.mode as "add" | "substract") || undefined}>
                        {item.formattedValue}
                      </SummaryLabel>
                    </li>
                  );
                })}
              </ul>

              <Separator className="mt-5 mb-4" />

              <div className="flex items-center justify-between">
                <SummaryLabel bold>{t("total")}</SummaryLabel>
                <SummaryLabel bold>{checkoutDetailsQuery.data?.formattedPayable}</SummaryLabel>
              </div>

              <p className="text-rapide-600 mt-5 flex items-center space-x-1 text-sm font-bold">
                <MaterialSymbol icon="encrypted" fill className="text-rapide-600" />
                <span>{t("secureCheckout")}</span>
              </p>
            </>
          )}
        </div>
      </Card>

      {checkoutDetailsQuery.isLoading || !user ? null : (
        <Card className="mt-5">
          {promoCode ? (
            <>
              <Label>{t("promoCode")}</Label>
              <Button color="secondary" variant="outline" onClick={() => setPromoCode(undefined)}>
                {promoCode}
                <X className="w-4 h-4 ml-2 text-red-600" />
              </Button>
            </>
          ) : (
            <FormPromoCode />
          )}
        </Card>
      )}
    </>
  );
}
