import SocialLoginButtonApple from "@/components/forms/SocialLoginButtonApple";
import SocialLoginFacebookButton from "@/components/forms/SocialLoginButtonFacebook";
import SocialLoginButtonGoogle from "@/components/forms/SocialLoginButtonGoogle";
import { useSingleSignOnMutation } from "@/lib/services/auth.services";
import { useTranslation } from "react-i18next";

import {
  LoginSocialApple,
  LoginSocialFacebook,
  LoginSocialGoogle,
} from "reactjs-social-login";

interface Props {
  onSuccess: (authToken: string) => void;
}

export default function SingleSignOn({ onSuccess }: Props) {
  const { t } = useTranslation("auth");
  const singleSignOnMutation = useSingleSignOnMutation();

  async function onSSO(
    provider: "facebook" | "google" | "apple",
    providerToken: string
  ) {
    const { authToken } = await singleSignOnMutation.mutateAsync({
      provider,
      providerToken,
    });

    onSuccess(authToken);
  }

  return (
    <div className="flex md:flex-nowrap flex-wrap items-center md:space-x-2 md:justify-normal justify-center">
      <p className="text-sm font-medium w-full text-center">{t("or")}</p>

      <LoginSocialFacebook
        appId={import.meta.env.VITE_FACEBOOK_APP_ID}
        fieldsProfile="public_profile, email"
        scope="public_profile, email"
        auth_type="rerequest"
        onResolve={({ data }: { data: FacebookLoginResponse }) => {
          if (data?.grantedScopes?.indexOf("email") === -1) {
            alert(t("ssoEmailPermissionRequired"));
          } else {
            data?.accessToken && onSSO("facebook", data.accessToken);
          }
        }}
        onReject={(error: any) => {
          console.error(error);

          if (typeof error === "object" && error.status === "unknown") {
            return;
          }

          if (typeof error === "object" && error?.data?.message) {
            alert(error?.data?.message);
          }
        }}
      >
        <SocialLoginFacebookButton className="md:mx-0 md:mt-0 mt-2 mx-1" />
      </LoginSocialFacebook>

      <LoginSocialGoogle
        scope="https://www.googleapis.com/auth/userinfo.profile email"
        client_id={import.meta.env.VITE_GOOGLE_APP_ID}
        onResolve={({ data }: { data: GoogleLoginResponse }) => {
          data?.access_token && onSSO("google", data.access_token);
        }}
        onReject={(error: any) => {
          console.error(error);

          if (typeof error === "object" && error?.data?.type === "popup_closed") {
            return;
          }

          if (typeof error === "object" && error?.data?.message) {
            alert(error?.data?.message);
          }
        }}
      >
        <SocialLoginButtonGoogle className="md:mx-0 md:mt-0 mt-2 mx-1" />
      </LoginSocialGoogle>

      <LoginSocialApple
        client_id={import.meta.env.VITE_APPLE_CLIENT_ID}
        redirect_uri={import.meta.env.VITE_APPLE_REDIRECT_URI}
        onResolve={({ data }: { data: AppleLoginResponse }) => {
          const { authorization } = data;
          const { id_token } = authorization;
          if (id_token) onSSO("apple", id_token);
        }}
        onReject={(error: any) => {
          console.error(error);

          if (typeof error === "object" && error?.data?.type === "popup_closed") {
            return;
          }

          if (typeof error === "object" && error?.data?.message) {
            alert(error?.data?.message);
          }
        }}
      >
        <SocialLoginButtonApple className="md:mx-0 md:mt-0 mt-2 mx-1" />
      </LoginSocialApple>
    </div>
  );
}
