import { Label } from "@/components/ui/label";
import cn from "@/lib/utils/cn.utils";

interface Props {
  size?: "sm" | "md" | "lg";
  color?: "rapide" | "attention" | "white" | "gray" | "red";
  label?: string;
  direction?: "vertical" | "horizontal";
  className?: string;
}

export default function Spinner({ size = "md", color = "rapide", label, direction = "horizontal", className }: Props) {
  const strokeClasses = {
    sm: "size-3",
    md: "size-3.5",
    lg: "size-4",
  };

  const strokeWidth = {
    sm: 3,
    md: 4,
    lg: 4,
  };

  const colorClasses = {
    rapide: "text-rapide-600",
    attention: "text-attention-600",
    white: "text-white",
    gray: "text-gray-500",
    red: "text-red-500",
  };

  return (
    <div className={cn("flex justify-center items-center", label && direction === "vertical" ? "flex-col" : "", className)}>
      <div>
        <svg
          className={cn("animate-spin", colorClasses[color], strokeClasses[size], label ? "-ml-1" : "")}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth={strokeWidth[size]} />

          <path
            fill="currentColor"
            className="opacity-75"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      </div>

      {label && <Label className={cn("mt-5", direction === "horizontal" ? "mt-0 ml-2" : "")}>{label}</Label>}
    </div>
  );
}
