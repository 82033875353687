import HeaderNav from "@/components/layout/header-nav";
import Logo from "@/components/layout/logo";
import { useLanguageUtils } from "@/lib/hooks/use-language-utils";
import { Globe, HelpCircle } from "lucide-react";

export default function Header() {
  const { language, alternateLanguage, switchLanguage } = useLanguageUtils();

  return (
    <header data-section="site-header" className="bg-white flex h-20 items-stretch px-4 shadow-md relative z-50">
      <Logo {...{ language: language as "fr" | "en", link: "#/" }} />
      <HeaderNav
        menus={[
          {
            items: [],
          },
          {
            items: [
              {
                label: "Support",
                icon: <HelpCircle />,
                href: language === "fr" ? "https://clicknpark.com/fr-CA/support" : "https://clicknpark.com/support",
              },
              {
                stripStyles: true,
                label: alternateLanguage.toUpperCase(),
                onClick: () => switchLanguage(alternateLanguage),
                locale: alternateLanguage,
                icon: <Globe />,
              },
            ],
          },
        ]}
      />
    </header>
  );
}
