import type {} from "@redux-devtools/extension"; // required for devtools typing
import dayjs, { Dayjs } from "dayjs";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface CheckoutState {
  step: number;
  setStep: (step: number) => void;
  nextStep: () => void;
  prevStep: () => void;

  start: string;
  setStart: (start: Date) => void;
  getStart: () => Dayjs;

  end: string;
  setEnd: (end: Date) => void;
  getEnd: () => Dayjs;

  parkIds: string[];
  setParkIds: (parkIds: string[]) => void;

  vehicleId?: string;
  setVehicleId: (vehicleId: string) => void;

  paymentRequired?: boolean;
  setPaymentRequired: (paymentRequired: boolean) => void;

  returnUrl?: string;
  setReturnUrl: (returnUrl: string) => void;

  guestSignupEmail?: string;
  setGuestSignupEmail: (email: string) => void;
}

export const useCheckoutStore = create<CheckoutState>()(
  devtools(
    (set, get) => ({
      /* Checkout progress step */
      step: 2,
      setStep: (step: number) => set({ step }),
      nextStep: () => set((state) => ({ step: state.step + 1 })),
      prevStep: () => set((state) => ({ step: state.step - 1 })),

      /* Dates */
      start: new Date().toISOString(),
      setStart: (start: Date) => set({ start: start.toISOString() }),
      getStart: () => dayjs(get().start),

      end: new Date().toISOString(),
      setEnd: (end: Date) => set({ end: end.toISOString() }),
      getEnd: () => dayjs(get().end),

      /* Park IDs */
      parkIds: [],
      setParkIds: (parkIds: string[]) => set({ parkIds }),

      /* Vehicle ID */
      vehicleId: undefined,
      setVehicleId: (vehicleId: string) => set({ vehicleId }),

      /* Payment required */
      paymentRequired: true,
      setPaymentRequired: (paymentRequired: boolean) => set({ paymentRequired }),

      /* Return path */
      returnUrl: undefined,
      setReturnUrl: (returnUrl: string) => set({ returnUrl }),

      /* Guest signup email */
      guestSignupEmail: undefined,
      setGuestSignupEmail: (email: string) => set({ guestSignupEmail: email }),
    }),
    {
      name: "checkout-storage",
    }
  )
);
