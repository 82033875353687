import Header from "@/components/layout/header";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

interface Props {
  children: React.ReactNode;
  className?: string;
}

export default function CheckoutShell({ children, className }: Props) {
  const { t } = useTranslation("common");

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className={twMerge("flex flex-1", className)}>{children}</main>
      <footer className="mt-24 flex justify-center text-xs text-gray-500 pb-10">
        <a href={t("privacyPolicyUrl")} target="_blank">
          {t("privacyPolicy")}
        </a>
        <span className="mx-2">&middot;</span>
        <a href={t("termsOfUseUrl")} target="_blank">
          {t("termsOfUse")}
        </a>
      </footer>
    </div>
  );
}
