import FormAuthLogin from "@/components/forms/FormAuthLogin";
import FormAuthOtp from "@/components/forms/FormAuthOtp";
import FormAuthPreflight from "@/components/forms/FormAuthPreflight";
import FormAuthResetPassword from "@/components/forms/FormAuthResetPassword";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { HandledError } from "@/lib/services/helpers/clicknpark-errors.helpers";
import { useCheckoutStore } from "@/lib/store/checkout.store";
import { useState } from "react";

interface Props {
  onAuth: (authToken?: string) => void;
  onGuest: () => void;
  onBack: () => void;
}

type Form = "preflight" | "login" | "otp" | "reset";

export default function FormAuthHandler({ onAuth, onGuest }: Props) {
  const { setGuestSignupEmail } = useCheckoutStore();
  const [form, setForm] = useState<Form>("preflight");
  const [email, setEmail] = useState<string | undefined>();
  const [error, setError] = useState<HandledError | undefined>();

  function resetError() {
    setError(undefined);
  }

  function handleError(error: HandledError) {
    setError(error);
  }

  async function handlePreflight(email: string, hasAccount: boolean, hasCompletedSignup: boolean) {
    setEmail(email);
    if (hasAccount && hasCompletedSignup) setForm("login");
    else if (hasAccount && !hasCompletedSignup) setForm("otp");
    else {
      setGuestSignupEmail(email);
      onGuest();
    }
  }

  function handleLogin(authToken: string) {
    resetError();
    onAuth(authToken);
  }

  return (
    <>
      {error ? (
        <Alert className="mb-3" variant="error" size="sm">
          {error.formatted.title ? <AlertTitle>{error.formatted.title}</AlertTitle> : null}
          {error.formatted.description ? <AlertDescription>{error.formatted.description}</AlertDescription> : null}
        </Alert>
      ) : null}

      {form === "preflight" && (
        <FormAuthPreflight
          {...{
            onBack: () => {},
            onPreflight: handlePreflight,
            onSingleSignOn: handleLogin,
            onError: handleError,
          }}
        />
      )}

      {email ? (
        <>
          {form === "login" ? (
            <FormAuthLogin
              {...{
                email,
                onForgotPassword: () => setForm("reset"),
                onCancel: () => setForm("preflight"),
                onSubmit: resetError,
                onSuccess: handleLogin,
                onError: handleError,
              }}
            />
          ) : null}

          {form === "reset" ? (
            <FormAuthResetPassword
              {...{
                email,
                onCancel: () => setForm("login"),
                onSubmit: resetError,
                onSuccess: handleLogin,
                onError: handleError,
              }}
            />
          ) : null}

          {form === "otp" ? (
            <FormAuthOtp
              {...{
                email,
                onCancel: () => setForm("preflight"),
                onSubmit: resetError,
                onSuccess: handleLogin,
                onError: handleError,
              }}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
}
