import { AiOutlineGoogle } from "react-icons/ai";
import { twMerge } from "tailwind-merge";

interface Props {
  className?: string;
}

export default function SocialLoginButtonGoogle({ className }: Props) {
  return (
    <div
      className={twMerge(
        "md:p-1 p-2 rounded-[5px] bg-[#DB4437] cursor-pointer hover:bg-[#c2321a] transition-colors hover:shadow-lg",
        className
      )}
    >
      <AiOutlineGoogle className="text-white" />
      <span className="sr-only">Google</span>
    </div>
  );
}
