import { clicknpark } from "@/lib/services/config/api";
import queryClient from "@/lib/services/config/query-client";
import {
  AddVehicleRequest,
  AddVehicleResponse,
  CPException,
  CPVehicle,
} from "@clicknpark/sdk";
import { useMutation, useQuery } from "@tanstack/react-query";

// Find Vehicle Models (GET request)
// ========================================

/**
 * Lazy find vehicle models by making a GET request to the server
 * @returns {Function} A function that takes a query string and returns a promise that resolves to the vehicle models data
 */
export const useLazyFindVehicleModels = () => {
  return async (query: string) => {
    return await queryClient.fetchQuery({
      queryKey: ["lazyFindVehicleModels", query],
      queryFn: async () => {
        return await clicknpark.vehicles.searchVehicleModels(query);
      },
    });
  };
};

// Get user's vehicles (GET)
// ========================================

export const GET_VEHICLES_QUERY_KEY = "get-vehicles";

export const getVehicles = (): Promise<CPVehicle[]> => {
  return clicknpark.vehicles.getVehicles();
};

export const useGetVehiclesQuery = ({ enabled }: { enabled: boolean }) => {
  return useQuery<CPVehicle[], CPException, CPVehicle[]>({
    queryKey: [GET_VEHICLES_QUERY_KEY],
    queryFn: getVehicles,
    enabled: enabled,
    refetchOnWindowFocus: false,
  });
};

// Add a vehicle (POST)
// ========================================

export const ADD_VEHICLE_MUTATION_KEY = "add-vehicle";

export const addVehicle = async (request: AddVehicleRequest) => {
  return await clicknpark.vehicles.addVehicle(request);
};

export const useAddVehicleMutation = () => {
  return useMutation<AddVehicleResponse, CPException, AddVehicleRequest>({
    mutationKey: [ADD_VEHICLE_MUTATION_KEY],
    mutationFn: addVehicle,
  });
};
