import i18next from "@/lib/i18n";
import { clicknpark } from "@/lib/services/config/api";
import { retry } from "@/lib/services/helpers/clicknpark-errors.helpers";

import { NumberFormattingUtils } from "@/lib/utils/formatting.utils";
import { ExtendedParkGroupListRequest } from "@/types/parks.types";
import {
  CPException,
  CPList,
  CPLocation,
  CPPark,
  CPParkAvailability,
  CPParkDirection,
  CPParkGroup,
  CPParkPrice,
  HourlyPriceRequest,
  ParkAvailabilityRequest,
  ParkGroupGetRequest,
  ParkListRequest,
  ParkPriceRequest,
} from "@clicknpark/sdk";
import { TravelMode } from "@googlemaps/google-maps-services-js";
import { QueryKey, useQuery } from "@tanstack/react-query";

// List Parks (GET)
// ========================================

export const LIST_PARKS_KEY = "parks";

export const listParks = async (
  request: ParkListRequest
): Promise<CPList<CPPark>> => {
  return await clicknpark.parks.list(request);
};

export const useListParksQuery = (
  request: ParkListRequest,
  queryOptions?: { enabled?: boolean }
) => {
  const enabled =
    typeof queryOptions?.enabled === "boolean" ? queryOptions.enabled : true;

  return useQuery<CPList<CPPark>, CPException, CPList<CPPark>, QueryKey>({
    queryKey: [LIST_PARKS_KEY],
    queryFn: () => listParks(request),
    staleTime: 1000 * 60 * 1, // 1 minute
    enabled,
    retry,
  });
};

// Get Park by ID (GET)
// ========================================

export const GET_PARK_QUERY_KEY = "park";

export const getPark = async (id: string): Promise<CPPark> => {
  return await clicknpark.parks.get(id);
};

export const useGetParkQuery = (
  id: string,
  queryOptions?: { enabled?: boolean }
) => {
  const enabled =
    typeof queryOptions?.enabled === "boolean" ? queryOptions.enabled : true;

  return useQuery<CPPark, CPException, CPPark, QueryKey>({
    queryKey: [GET_PARK_QUERY_KEY, id],
    queryFn: () => getPark(id),
    staleTime: 1000 * 60 * 1, // 1 minute,
    enabled,
    retry,
  });
};

// Get Park Directions from provided location (GET)
// ========================================

export const GET_PARK_DIRECTIONS_QUERY_KEY = "park-directions";

export const getParkDirections = async (
  id: string,
  location?: CPLocation
): Promise<CPParkDirection> => {
  if (!location) throw new Error("Location is required");
  return await clicknpark.parks.getDirection(id, {
    originLatitude: location.latitude,
    originLongitude: location.longitude,
    mode: TravelMode.walking,
  });
};

export const useGetParkDirectionsQuery = (
  id: string,
  location: CPLocation,
  queryOptions?: { enabled?: boolean }
) => {
  const enabled =
    typeof queryOptions?.enabled === "boolean" ? queryOptions.enabled : true;

  return useQuery<CPParkDirection, CPException, CPParkDirection, QueryKey>({
    queryKey: [GET_PARK_DIRECTIONS_QUERY_KEY, id],
    queryFn: () => getParkDirections(id, location),
    staleTime: 1000 * 60 * 1, // 1 minute
    enabled,
    retry,
  });
};

// Get Park Price (GET)
// ========================================

export const GET_PARK_PRICE_QUERY_KEY = "park-price";

export const getParkPrice = async (
  id: string,
  request: ParkPriceRequest
): Promise<CPParkPrice> => {
  return await clicknpark.parks.getPrice(id, request);
};

export const useGetParkPriceQuery = (
  id: string,
  request: ParkPriceRequest,
  queryOptions?: { enabled?: boolean }
) => {
  const enabled =
    typeof queryOptions?.enabled === "boolean" ? queryOptions.enabled : true;

  return useQuery<CPParkPrice, CPException, CPParkPrice, QueryKey>({
    queryKey: [GET_PARK_PRICE_QUERY_KEY, request], // React query needs this to work properly, but it ain't necessary
    queryFn: () => getParkPrice(id, request),
    staleTime: 0, // The data will never be considered fresh and will always be refetched
    enabled,
    retry,
  });
};

// Get Park Slot Prices (GET)
// ========================================

export const GET_PARK_SLOT_PRICES_QUERY_KEY = "park-slot-prices";

export type SlotOption = {
  slot: number;
  request: HourlyPriceRequest;
};

export type Slot = {
  durationInHours: number;
  formattedPrice: string;
  currency?: string;
  price: number;
};

export const useGetParkSlotPricesQuery = (
  id: string,
  requests: SlotOption[],
  queryOptions?: { enabled?: boolean }
) => {
  const enabled =
    typeof queryOptions?.enabled === "boolean" ? queryOptions.enabled : true;

  return useQuery<Slot[], CPException, Slot[], QueryKey>({
    queryKey: [GET_PARK_SLOT_PRICES_QUERY_KEY],
    queryFn: async () => {
      const promises = requests.map((request) =>
        getParkPrice(id, request.request).then((result) => {
          return {
            durationInHours: request.slot,
            currency: result.currency,
            price: result.total,
            formattedPrice: NumberFormattingUtils.formatAmountWithoutCurrency(
              result.total,
              i18next.language,
              result.currency
            ),
          };
        })
      );

      return await Promise.all(promises);
    },
    staleTime: 50000, // 5 minute
    enabled,
    retry,
  });
};

// Get Park Availability (GET)
// ========================================

export const GET_PARK_AVAILABILITY_QUERY_KEY = "park-availability";


export const getParkAvailability = async (
  id: string,
  request: ParkAvailabilityRequest
): Promise<CPParkAvailability> => {
  return await clicknpark.parks.getAvailability(id, request);
};

export const useGetParkAvailabilityQuery = (
  id: string,
  request: ParkAvailabilityRequest,
  queryOptions?: { enabled?: boolean }
) => {
  const enabled =
    typeof queryOptions?.enabled === "boolean" ? queryOptions.enabled : true;

  return useQuery<
    CPParkAvailability,
    CPException,
    CPParkAvailability,
    QueryKey
  >({
    queryKey: [GET_PARK_AVAILABILITY_QUERY_KEY, request], // React query needs this to work properly, but it ain't necessary
    queryFn: () => getParkAvailability(id, request),
    staleTime: 0, // The data will never be considered fresh and will always be refetched
    enabled,
    retry,
  });
};

// List Park Groups (GET)
// ========================================

export const LIST_PARKS_GROUPS_QUERY_KEY = "listParksGroups";

export const listParkGroups = async (
  request: ExtendedParkGroupListRequest
): Promise<CPList<CPParkGroup>> => {
  return await clicknpark.parkGroups.list(request);
};

export const useListParksGroupsQuery = (
  request: ExtendedParkGroupListRequest
) => {
  return useQuery<
    CPList<CPParkGroup>,
    CPException,
    CPList<CPParkGroup>,
    QueryKey
  >({
    queryKey: [
      LIST_PARKS_GROUPS_QUERY_KEY,
      request.location.latitude,
      request.location.longitude,
    ],
    queryFn: () => listParkGroups(request),
  });
};

// Get Park Group by ID (GET)
// ========================================

export const GET_PARK_GROUP_QUERY_KEY = "parkGroup";

export const getParkGroup = async (
  id: string,
  request?: ParkGroupGetRequest
): Promise<CPParkGroup> => {
  return await clicknpark.parkGroups.getById(id, request);
};

export const useGetParkGroupQuery = (
  id: string,
  request?: ParkGroupGetRequest
) => {
  return useQuery<CPParkGroup, CPException, CPParkGroup, QueryKey>({
    queryKey: [GET_PARK_GROUP_QUERY_KEY, id],
    queryFn: () => getParkGroup(id, request),
    refetchOnWindowFocus: false,
  });
};
