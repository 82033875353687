import { clicknpark } from "@/lib/services/config/api";
import { CPException, GetPromoCodesResponse, RedeemPromoCodeResponse } from "@clicknpark/sdk";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

// Fetch promo codes (GET)
// ========================================

export const GET_PROMO_CODES_QUERY_KEY = "get-promo-codes";

export const getPromoCodes = async () => {
  return clicknpark.promoCodes.getPromoCodes();
};

export const useGetPromoCodesQuery = () => {
  return useQuery<GetPromoCodesResponse, CPException>({
    queryKey: [GET_PROMO_CODES_QUERY_KEY],
    queryFn: getPromoCodes,
  });
};

// Redeem promo code (POST)
// ========================================

export const REDEEM_PROMO_CODE_MUTATION_KEY = "redeem-promo-code";

export const redeemPromoCode = async (code: string) => {
  return await clicknpark.promoCodes.redeemPromoCode(code);
};

export const useRedeemPromoCodeMutation = () => {
  const queryClient = useQueryClient();
  return useMutation<RedeemPromoCodeResponse, CPException, string>({
    mutationKey: [REDEEM_PROMO_CODE_MUTATION_KEY],
    mutationFn: (code: string) => redeemPromoCode(code),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [GET_PROMO_CODES_QUERY_KEY] }),
  });
};
