import StepNumber from "@/components/checkout/StepNumber";
import FormNoPayment from "@/components/forms/FormNoPayment";
import FormPayment from "@/components/forms/FormPayment";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import Heading from "@/components/ui/heading";
import { Separator } from "@/components/ui/separator";
import Spinner from "@/components/ui/spinner";
import { contact } from "@/lib/configs/contact.config";
import { useLanguageUtils } from "@/lib/hooks/use-language-utils";
import { usePayMutation } from "@/lib/services/checkout.services";
import { AppError, HandledError, handleError } from "@/lib/services/helpers/clicknpark-errors.helpers";
import getMetadata from "@/lib/services/helpers/metadata.helpers";
import {
  deleteSavedPaymentIntent,
  getSavedPaymentIntent,
  isSavedPaymentIntentExpired,
  savePaymentIntent,
  saveReservationId,
  useCreateReservationMutation,
} from "@/lib/services/reservations.services";
import { useCheckoutStore } from "@/lib/store/checkout.store";
import cn from "@/lib/utils/cn.utils";
import { DateUtils } from "@/lib/utils/date.utils";
import { keys } from "@/lib/utils/storage.utils";
import UtmUtils from "@/lib/utils/utm.utils";
import * as Sentry from "@sentry/react";
import { Elements } from "@stripe/react-stripe-js";
import { Stripe, StripeElementLocale, loadStripe } from "@stripe/stripe-js";
import { useLocalStorage } from "@uidotdev/usehooks";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  onBack: () => void;
  onDatesReset: () => void;
}

async function retryLoadStripe(retries = 3, delay = 2000) {
  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      return await loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY as string);
    } catch (error) {
      console.warn(`Payment services: loading attempt ${attempt} failed. Retrying...`);
      if (attempt < retries) await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }

  throw new Error("Payment services failed to load after multiple attempts.");
}

export default function CheckoutPayment({ onBack, onDatesReset }: Props) {
  const { t } = useTranslation("payment");
  const { language } = useLanguageUtils();

  const [promoCode] = useLocalStorage<string | undefined>(keys.ACTIVE_PROMO_CODE, undefined);
  const { vehicleId, parkIds, paymentRequired, setReturnUrl, getStart, getEnd, setStart } = useCheckoutStore();

  const { step } = useCheckoutStore();
  const isActiveStep = step === 3;

  const [clientSecret, setClientSecret] = useState<string | undefined>();
  const [datesAreOutdated, setDatesAreOutdated] = useState(false);
  const [parkNotAvailableAnymore, setParkNotAvailableAnymore] = useState(false);
  const [error, setError] = useState<HandledError | undefined>();
  const [displayPayment, setDisplayPayment] = useState(false);

  const createReservationMutation = useCreateReservationMutation();
  const payMutation = usePayMutation();

  const [stripeObject, setStripeObject] = useState<Stripe | undefined>();

  useEffect(() => {
    retryLoadStripe()
      .then((stripe) => {
        if (stripe) setStripeObject(stripe);
      })
      .catch((error) => {
        Sentry.captureException(error);
        alert(
          t("stripeUnhandledError", {
            email: contact.helpEmail[language],
            phone: contact.helpPhone.formatted,
          })
        );
      });
  }, []);

  useEffect(() => {
    if (isActiveStep) {
      (async () => {
        try {
          const savedData = getSavedPaymentIntent();
          let reservationId = savedData?.reservationId || undefined;
          let paymentIntent = savedData?.paymentIntent || undefined;

          if (isSavedPaymentIntentExpired()) {
            deleteSavedPaymentIntent();
            reservationId = undefined;
            paymentIntent = undefined;
          }

          if (!reservationId) {
            let startDate = dayjs(getStart());
            let endDate = dayjs(getEnd());

            if (startDate.isBefore(DateUtils.getClosestQuarter(dayjs()))) {
              startDate = dayjs();
              setStart(startDate.toDate());
            }

            if (endDate.isBefore(startDate)) {
              throw new AppError("OUTDATED_DATES_ERROR", t("outdatedDatesError"));
            }

            const data = await createReservationMutation.mutateAsync({
              parkingId: parkIds[0],
              startTime: startDate.toISOString(),
              endTime: endDate.toISOString(),
              vehicleId,
              source: "ONLINE_CHECKOUT",
              metadata: getMetadata(),
              promoCode,
            });

            reservationId = data.reservation.id;
            saveReservationId(reservationId);
          }

          setReturnUrl(UtmUtils.appendUtmStringToUrl(`${window.location.origin}/return/${reservationId}/`));

          if (paymentRequired) {
            if (!paymentIntent) {
              const data = await payMutation.mutateAsync(reservationId);
              paymentIntent = data.paymentIntent;
              savePaymentIntent(paymentIntent);
            }

            setClientSecret(paymentIntent);
          }

          setDisplayPayment(true);
        } catch (error) {
          const handledError = handleError(error);

          console.log("handledError", handledError);

          // Special case for outdated dates
          if (handledError.code === "OUTDATED_DATES_ERROR") {
            setDatesAreOutdated(true);
          }

          // Special case for park not available anymore
          else if (handledError.code === "320") {
            setParkNotAvailableAnymore(true);
          }

          setError(handledError);
        }
      })();
    }
  }, [isActiveStep]);

  return (
    <Card>
      {!datesAreOutdated ? (
        <div className="flex items-center justify-between">
          <div className="flex">
            <StepNumber number={3} />
            <Heading level="h2" size="sm" className="ml-3" color="simple">
              {t("payment")}
            </Heading>
          </div>
        </div>
      ) : null}

      {error ? (
        <Alert variant="error" className={cn(datesAreOutdated ? "" : "mt-5")}>
          {error.formatted.title ? <AlertTitle>{error.formatted.title}</AlertTitle> : null}
          {error.formatted.description ? <AlertDescription>{error.formatted.description}</AlertDescription> : null}
          {datesAreOutdated || parkNotAvailableAnymore ? (
            <Button size="sm" color="secondary" variant="outline" className="!mt-2 text-white" onClick={onDatesReset}>
              {t("selectNewDates")}
            </Button>
          ) : null}
        </Alert>
      ) : null}

      {!datesAreOutdated && !error && isActiveStep ? (
        <>
          <Separator className="mb-5 mt-3" />

          {paymentRequired ? (
            <>
              {!displayPayment || !stripeObject ? (
                <div className="relative z-0 flex h-[100px] items-center justify-center">
                  <Spinner />
                </div>
              ) : (
                <Elements
                  {...{
                    options: {
                      appearance,
                      clientSecret,
                      locale: language as StripeElementLocale,
                      loader: "always",
                    },
                    stripe: stripeObject,
                  }}
                >
                  {clientSecret ? <FormPayment {...{ clientSecret, onBack }} /> : null}
                </Elements>
              )}
            </>
          ) : (
            <>
              {!displayPayment ? (
                <div className="relative z-0 flex h-[100px] items-center justify-center">
                  <Spinner />
                </div>
              ) : (
                <FormNoPayment
                  {...{
                    onBack,
                    reservationId: createReservationMutation.data?.reservation.id,
                  }}
                />
              )}
            </>
          )}
        </>
      ) : null}
    </Card>
  );
}


const appearance = {
  rules: {
    ".Tab": {
      marginBottom: "10px",
      border: "1px solid #E0E6EB",
      boxShadow:
        "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)",
    },

    ".Tab:hover": {
      color: "var(--colorText)",
    },

    ".Tab--selected": {
      borderColor: "#E0E6EB",
      boxShadow:
        "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)",
    },

    ".Input": {
      marginTop: "8px",
      marginBottom: "8px",
      fontSize: "0.875rem",
      fontWeight: "500",
      padding: "12px 12px 10px 12px",
      fontFamily: "Gilroy, system-ui",
      border: "1px solid rgb(226 232 240 / var(--tw-border-opacity))",
      boxShadow:
        "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
    },

    ".Input--invalid": {
      boxShadow:
        "0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 1px var(--colorDanger)",
    },

    ".Label": {
      fontSize: "0.875rem",
      fontWeight: "600",
      fontFamily: "Gilroy, system-ui",
      color: "#000",
    },

    ".Error": {
      fontSize: "0.875rem",
      fontWeight: "600",
      fontFamily: "Gilroy, system-ui",
      color: "#ef4444",
      marginTop: "10px",
    },

    // See all supported class names and selector syntax below
  },
};
