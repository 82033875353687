import { GRecaptchaActionName } from "@/types/d.ts/global";
import { useEffect, useState } from "react";
import { isScriptInjected } from "../utils/scripts";


const scriptId = "recaptcha";
const scriptSrc = `https://www.google.com/recaptcha/api.js?render=${import.meta.env.VITE_RECAPTCHA_SITE_KEY}`;
export function useRecaptcha() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (isScriptInjected(scriptId)) {
      setIsLoading(false);
      return;
    }
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = scriptSrc;
    script.async = true;
    script.onload = (() => {
      setIsLoading(false);
    })
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [setIsLoading]);

  async function getToken(action: GRecaptchaActionName): Promise<string> {
    const token = await window.grecaptcha.execute(import.meta.env.VITE_RECAPTCHA_SITE_KEY, { action: action })
    return token;
  }

  return {
    isLoading,
    getToken
  }
}