/*

  Documentation:
  - https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm
 - https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#clear_the_ecommerce_object

*/

import { logger } from "@/lib/services/debug/logger.services";
import UtmUtils from "@/lib/utils/utm.utils";
import { CPPark, CPParkV2, CPUser } from "@clicknpark/sdk";
import posthog from "posthog-js";
import TagManager from "react-gtm-module";

export class TrackingServices {
  /* Generic tracking methods */

  static setUserId(user: CPUser) {
    if (user) {
      posthog.identify(user.objectId, {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
      });

      logger.info("[Tracking] Set user data in posthog", {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
      });

      TagManager.dataLayer({ dataLayer: { user_id: user.objectId } });

      logger.info("[Tracking] Set user ID in GA4", user.objectId);
    }
  }

  static resetUserId() {
    posthog.reset();
    TagManager.dataLayer({ dataLayer: { user_id: null } });
    logger.info("[Tracking] Reset user data and ID");
  }

  static trackPageView() {
    posthog.capture("$pageview");
  }

  // https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#clear_the_ecommerce_object
  static clearGtmEcommerceDataLayer() {
    TagManager.dataLayer({ dataLayer: { ecommerce: null } });
  }

  static trackGtmEvent(event: string, data: Record<string, any>) {
    TagManager.dataLayer({
      dataLayer: {
        event,
        ...data,
        ...UtmUtils.getSavedUtmParams(),
      },
    });
  }

  /* Specific tracking methods */

  // User behavior (interactions with specific UI elements, etc.)
  // ===========================================

  static trackLogout() {
    posthog.capture("web_logout");
    logger.info("[TRACKING] Posthog:", "User logged out");
  }

  static trackSelectedExistingVehicle(vehicleId: string) {
    posthog.capture("web_selected_existing_vehicle", {
      vehicleId,
    });

    logger.info("[TRACKING] Posthog:", "Selected existing vehicle", vehicleId);
  }

  static trackAddedNewVehicle(vehicleId: string) {
    posthog.capture("web_added_new_vehicle", {
      vehicleId,
    });

    logger.info("[TRACKING] Posthog:", "Added new vehicle", vehicleId);
  }

  static trackCheckoutMoveBackToDuration() {
    posthog.capture("web_checkout_move_back_to_duration");
    logger.info("[TRACKING] Posthog:", "Moved back to duration");
  }

  static trackCheckoutMoveBackToContact() {
    posthog.capture("web_checkout_move_back_to_contact");
    logger.info("[TRACKING] Posthog:", "Moved back to contact");
  }

  static trackCheckoutMoveToPayment() {
    posthog.capture("web_checkout_move_to_payment");
    logger.info("[TRACKING] Posthog:", "Moved to payment");
  }

  // Track conversion events (e.g. moving to checkout, payment success, etc.)
  // ===========================================

  static trackParkBeginCheckout(price: number, currency: string, park: CPPark) {
    posthog.capture("web_park_begin_checkout", {
      currency: currency,
      value: price,
      items: [
        {
          item_id: park.id,
          item_name: park.address.line1,
          item_category: "reservation",
          price: price,
          quantity: 1,
        },
      ],
    });

    logger.info("[TRACKING] Posthog:", "web_park_begin_checkout", {
      currency: currency,
      value: price,
      items: [
        {
          item_id: park.id,
          item_name: park.address.line1,
          item_category: "reservation",
          price: price,
          quantity: 1,
        },
      ],
    });

    this.clearGtmEcommerceDataLayer();
    this.trackGtmEvent("begin_checkout", {
      ecommerce: {
        currency: currency,
        value: price,
        items: [
          {
            item_id: park.id,
            item_name: park.address.line1,
            item_category: "reservation",
            price: price,
            quantity: 1,
          },
        ],
      },
    });

    logger.info("[TRACKING] G4A:", "begin_checkout", "begin_checkout", {
      ecommerce: {
        currency: currency,
        value: price,
        items: [
          {
            item_id: park.id,
            item_name: park.address.line1,
            item_category: "reservation",
            price: price,
            quantity: 1,
          },
        ],
      },
    });
  }

  static trackParkPurchase(
    price: number,
    tax: number,
    transactionId: string,
    currency: string,
    park: CPParkV2
  ) {
    posthog.capture("web_park_purchase", {
      currency: currency,
      value: price / 100, // CheckoutSession returns price in cents
      items: [
        {
          item_id: park.objectId,
          item_name: park.address.line1,
          item_category: "reservation",
          price: price / 100, // CheckoutSession returns price in cents
          tax: tax / 100, // CheckoutSession returns tax in cents
          quantity: 1,
        },
      ],
    });

    this.clearGtmEcommerceDataLayer();
    this.trackGtmEvent("purchase", {
      ecommerce: {
        currency: currency,
        value: price / 100, // CheckoutSession returns price in cents
        transaction_id: transactionId,
        items: [
          {
            item_id: park.objectId,
            item_name: park.address.line1,
            item_category: "reservation",
            price: price / 100, // CheckoutSession returns price in cents
            tax: tax / 100, // CheckoutSession returns tax in cents
            quantity: 1,
          },
        ],
      },
    });

    logger.info("[TRACKING] G4A:", "purchase", {
      ecommerce: {
        currency: currency,
        value: price / 100, // CheckoutSession returns price in cents
        items: [
          {
            item_id: park.objectId,
            item_name: park.address.line1,
            item_category: "reservation",
            price: price / 100, // CheckoutSession returns price in cents
            tax: tax / 100, // CheckoutSession returns tax in cents
            quantity: 1,
          },
        ],
      },
    });
  }
}
