import cn from "@/lib/utils/cn.utils";
import * as LabelPrimitive from "@radix-ui/react-label";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

const labelVariants = cva("text-sm font-semibold leading-tight peer-disabled:cursor-not-allowed peer-disabled:opacity-70");

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & VariantProps<typeof labelVariants>
>(({ className, ...props }, ref) => <LabelPrimitive.Root ref={ref} className={cn("block mb-2", labelVariants(), className)} {...props} />);

Label.displayName = LabelPrimitive.Root.displayName;

const LabelDescription = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <span
    className={cn(
      "block text-sm text-slate-500 dark:text-slate-400",
      className
    )}
  >
    {children}
  </span>
);

export { Label, LabelDescription };
