import Spinner from "@/components/ui/spinner";
import { useGetReservationQuery } from "@/lib/services/reservations.services";
import { TrackingServices } from "@/lib/services/tracking.services";
import UtmUtils from "@/lib/utils/utm.utils";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export default function Return() {
  const { t } = useTranslation("return");
  const { reservationId } = useParams<{ reservationId: string }>();

  const getReservationQuery = useGetReservationQuery(reservationId, {
    enabled: !!reservationId,
  });

  const reservation = getReservationQuery?.data;

  useEffect(() => {
    if (reservation) {
      const tps = reservation.checkoutSession?.taxes?.TPS;
      const tvq = reservation.checkoutSession?.taxes?.TVQ;
      const taxAmount = (tps || 0) + (tvq || 0);
      const transactionId = reservation.objectId;

      TrackingServices.trackParkPurchase(
        reservation.checkoutSession.payableAmount,
        taxAmount,
        transactionId,
        reservation.checkoutSession.currency,
        reservation.parkObject
      );

      setTimeout(() => {
        window.location.href = UtmUtils.appendUtmStringToUrl(`${import.meta.env.VITE_CLICKNPARK_WEB_APP_URL}/account/reservations/${reservationId}`);
      }, 5000);
    }
  }, [reservation]);

  
  if (!getReservationQuery.isLoading && getReservationQuery.isSuccess && !reservation) {
    return (
      <div className="h-full flex flex-col items-center justify-center text-md text-red-600 p-10 text-center">
        <p>{t("reservationNotFound")}</p>
      </div>
    );
  }

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <Spinner />
    </div>
  );
}
