import { MaterialSymbol } from "react-material-symbols";

interface Props {
  direction?: "up" | "down" | "left" | "right";
  size?: number;
  className?: string;
}

export default function IconChevron({
  direction = "down",
  size = 20,
  className,
}: Props) {
  switch (direction) {
    case "up":
      return (
        <MaterialSymbol icon="expand_less" size={size} className={className} />
      );
    case "down":
      return (
        <MaterialSymbol icon="expand_more" size={size} className={className} />
      );
    case "left":
      return (
        <MaterialSymbol icon="chevron_left" size={size} className={className} />
      );
    case "right":
      return (
        <MaterialSymbol
          icon="chevron_right"
          size={size}
          className={className}
        />
      );
  }
}
