import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import Spinner from "@/components/ui/spinner";
import { useRecaptcha } from "@/lib/hooks/use-recaptcha";
import { useToast } from "@/lib/hooks/use-toast";
import { useSignUpAsGuestMutation } from "@/lib/services/auth.services";
import { handleError } from "@/lib/services/helpers/clicknpark-errors.helpers";
import {
  getSavedPaymentIntent,
  hasSavedPaymentIntent,
  isSavedPaymentIntentExpired,
  useAttachVehicleToReservationMutation,
} from "@/lib/services/reservations.services";
import { TrackingServices } from "@/lib/services/tracking.services";
import {
  GET_USER_QUERY_KEY,
  GET_USER_SETTINGS_QUERY_KEY,
  useAcceptTosMutation,
  useGetUserQuery,
  useGetUserSettingsQuery,
  useUpdateUserMutation,
  userUpdateUserSettingsMutation,
} from "@/lib/services/user.services";
import { useAddVehicleMutation, useGetVehiclesQuery } from "@/lib/services/vehicles.services";
import { useCheckoutStore } from "@/lib/store/checkout.store";
import { PhoneFormattingUtils, StringFormattingUtils } from "@/lib/utils/formatting.utils";
import { keys } from "@/lib/utils/storage.utils";
import { CPVehicle, isVehicleWithModel } from "@clicknpark/sdk";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { useCookies } from "react-cookie";
import { Trans, useTranslation } from "react-i18next";

interface Props {
  onBack: () => void;
  onAuth: (authToken?: string) => void;
  onContinue: () => void;
}

// This function generates the name for a vehicle
function getVehicleName(vehicle: CPVehicle): string {
  if (isVehicleWithModel(vehicle)) {
    return `${vehicle.make} ${vehicle.model} [${vehicle.plate}]`;
  } else if (vehicle.noModelString) {
    if (vehicle.noModelString === "_UNKNOWN_") {
      return vehicle.plate;
    } else {
      return `${vehicle.noModelString} [${vehicle.plate}]`;
    }
  } else {
    return vehicle.plate;
  }
}

export default function FormContact({ onBack, onAuth, onContinue }: Props) {
  const { t } = useTranslation(["contact", "validation"]);
  const [cookie] = useCookies([keys.AUTH_TOKEN]);
  const authToken = cookie[keys.AUTH_TOKEN];
  const { setVehicleId, guestSignupEmail } = useCheckoutStore();
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const { getToken } = useRecaptcha();

  const signupAsGuest = useSignUpAsGuestMutation();
  const enabled = !!authToken; // Only fetch user data if the user is logged in

  // Queries
  // =============================
  const userQuery = useGetUserQuery({ enabled });
  const user = userQuery.data;
  const userSettingsQuery = useGetUserSettingsQuery({ enabled });
  const userSettings = userSettingsQuery.data;
  const newsletterAlreadyOptedIn = userSettings?.email.acceptsMarketing;
  const userVehiclesQuery = useGetVehiclesQuery({ enabled });

  // Mutations
  // =============================
  const addVehicleMutation = useAddVehicleMutation();
  const updateUserMutation = useUpdateUserMutation();
  const updateUserSettingsMutation = userUpdateUserSettingsMutation();
  const attachVehicleToReservationMutation = useAttachVehicleToReservationMutation();
  const acceptTermsOfUse = useAcceptTosMutation();

  const vehicles = useMemo(() => {
    return userVehiclesQuery.data?.map((vehicle) => {
      if (isVehicleWithModel(vehicle)) {
        return {
          id: vehicle.id,
          name: getVehicleName(vehicle),
          model: vehicle.model,
          make: vehicle.make,
          plate: vehicle.plate,
        };
      } else {
        return {
          id: vehicle.id,
          name: getVehicleName(vehicle),
          noModelString: vehicle.noModelString,
          plate: vehicle.plate,
        };
      }
    });
  }, [userVehiclesQuery.data]);

  // Form
  // =============================
  const formSchema = z.object({
    vehicle: z.string().min(1, t("validation:vehicleRequired")),
    plate: z.string().min(1, t("validation:licencePlateRequired")),
    firstName: z.string().min(1, t("validation:firstNameRequired")),
    lastName: z.string().min(1, t("validation:lastNameRequired")),
    phone: z.string().min(1, t("validation:phoneRequired")),
    newsletterOptIn: z.boolean().default(false).optional(),
    acceptTermsOfUse: guestSignupEmail
      ? z
          .boolean()
          .default(false)
          .refine((value) => value === true, {
            message: t("validation:acceptTermsOfUseRequired"),
          })
      : z.boolean().default(false).optional(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      vehicle: "",
      plate: "",
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      phone: user?.phone?.formatted || "",
      newsletterOptIn: newsletterAlreadyOptedIn,
      acceptTermsOfUse: guestSignupEmail ? false : true, // only guest user have to accept terms of use
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      /* if the user is a guest, we need to sign them up and set the auth token immediatly */

      if (guestSignupEmail) {
        const recaptchaToken = await getToken("guest_signup");
        const { authToken } = await signupAsGuest.mutateAsync({ email: guestSignupEmail.toLowerCase().trim(), recaptchaToken: recaptchaToken });
        onAuth(authToken);
      }
    } catch (error) {
      const handledError = handleError(error);
      if (handledError.handled) toast({ ...handledError.formatted, variant: "destructive" });
      else toast({ title: handledError.code, description: handledError.message, variant: "destructive" });
    }

    let vid: string | undefined;

    try {
      /* then we can update the user's data */

      if (values.vehicle === "new" || values.vehicle === "first") {
        const data = await addVehicleMutation.mutateAsync({
          plate: values.plate,
          noModelString: "_UNKNOWN_",
        });

        vid = data.object.objectId;
        TrackingServices.trackAddedNewVehicle(vid);
        setVehicleId(vid);
      } else {
        vid = values.vehicle;
        setVehicleId(values.vehicle);
        TrackingServices.trackSelectedExistingVehicle(values.vehicle);
      }

      // Specific use case: attach vehicle to existing reservation
      if (hasSavedPaymentIntent() && !isSavedPaymentIntentExpired()) {
        const savedData = getSavedPaymentIntent();
        if (savedData?.reservationId) {
          await attachVehicleToReservationMutation.mutateAsync({
            reservationId: savedData.reservationId,
            vehicleId: vid,
          });
        }
      }

      /* update user data */

      await updateUserMutation.mutateAsync({
        updates: {
          firstName: values.firstName,
          lastName: values.lastName,
          phone: { number: PhoneFormattingUtils.getE164Value(values.phone) },
        },
      });

      /* update user settings */

      await updateUserSettingsMutation.mutateAsync({
        email: {
          acceptsMarketing: values.newsletterOptIn || false,
        },
        push: {
          acceptsMarketing: userSettings?.push.acceptsMarketing || false,
        },
      });

      if (guestSignupEmail) {
        await acceptTermsOfUse.mutateAsync();
      }

      /* invalidate user queries */

      await queryClient.invalidateQueries({
        queryKey: [GET_USER_QUERY_KEY, GET_USER_SETTINGS_QUERY_KEY],
      });

      onContinue();
    } catch (error) {
      const handledError = handleError(error);
      if (handledError.handled) toast({ ...handledError.formatted, variant: "destructive" });
      else toast({ title: handledError.code, description: handledError.message, variant: "destructive" });
    }
  }

  const vehicle = form.watch("vehicle");

  useEffect(() => {
    form.setValue("newsletterOptIn", newsletterAlreadyOptedIn);
  }, [newsletterAlreadyOptedIn]);

  useEffect(() => {
    form.setValue("vehicle", !vehicles || vehicles.length === 0 ? "first" : "");
  }, [vehicles]);

  useEffect(() => {
    if (vehicle === "new") form.setValue("plate", "");
    else {
      const selectedVehicle = vehicles?.find((v) => v.id === vehicle);
      if (selectedVehicle) form.setValue("plate", selectedVehicle.plate);
    }
  }, [vehicle]);

  if (userQuery.isFetching || userSettingsQuery.isFetching || userVehiclesQuery.isFetching) {
    return (
      <div className="relative z-0 flex h-[100px] items-center justify-center">
        <Spinner />
      </div>
    );
  }

  const PlateField = (
    <FormField
      name="plate"
      control={form.control}
      render={({ field }) => (
        <FormItem>
          <Label>{t("contact:plateNumber")}</Label>
          <FormControl>
            <Input {...field} value={StringFormattingUtils.toNoSpaces(field.value)} />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <div className="flex space-x-4 space-y-0">
          <FormField
            name="firstName"
            control={form.control}
            render={({ field }) => (
              <FormItem className="flex-1">
                <Label>{t("contact:firstName")}</Label>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            name="lastName"
            control={form.control}
            render={({ field }) => (
              <FormItem className="flex-1">
                <Label>{t("contact:lastName")}</Label>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <FormField
          name="phone"
          control={form.control}
          render={({ field }) => (
            <FormItem>
              <Label>{t("contact:phone")}</Label>
              <FormControl>
                <Input {...field} value={PhoneFormattingUtils.formatPhoneNumber(field.value)} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {vehicles && vehicles.length > 0 ? (
          <div className="flex space-x-4 space-y-0">
            <FormField
              name="vehicle"
              control={form.control}
              render={({ field }) => (
                <FormItem className="flex-1">
                  <Label>{t("contact:yourVehicles")}</Label>
                  <FormControl>
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                      <SelectTrigger>
                        <SelectValue placeholder={t("contact:selectVehicle")} />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="new">{t("contact:newVehicle")}</SelectItem>
                        {vehicles.map((vehicle) => (
                          <SelectItem key={vehicle.id} value={vehicle.id}>
                            {vehicle.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {vehicle === "new" ? PlateField : null}
          </div>
        ) : (
          <>
            <FormField
              name="vehicle"
              control={form.control}
              render={({ field }) => (
                <FormItem className="hidden">
                  <FormControl>
                    <Input {...field} type="hidden" value={field.value} />
                  </FormControl>
                </FormItem>
              )}
            />
            {PlateField}
          </>
        )}

        <Alert variant="softError" size="sm">
          <AlertDescription>{t("contact:plateWarning")}</AlertDescription>
        </Alert>

        {guestSignupEmail ? (
          <FormField
            control={form.control}
            name="acceptTermsOfUse"
            render={({ field }) => (
              <FormItem className="flex flex-row items-center space-x-3 md:space-y-0 space-y-0">
                <FormControl>
                  <Checkbox id="acceptTermsOfUse" checked={field.value} onCheckedChange={field.onChange} />
                </FormControl>
                <Label htmlFor="acceptTermsOfUse">
                  <Trans
                    i18nKey="contact:disclaimer"
                    components={{
                      tosLink: (
                        <a href={t("contact:tosLink")} className="text-rapide-600 underline hover:no-underline">
                          {t("contact:tosText")}
                        </a>
                      ),
                      privacyLink: (
                        <a href={t("contact:privacyLink")} className="text-rapide-600 underline hover:no-underline">
                          {t("contact:privacyText")}
                        </a>
                      ),
                    }}
                  />
                  <FormMessage />
                </Label>
              </FormItem>
            )}
          />
        ) : null}

        <FormField
          control={form.control}
          name="newsletterOptIn"
          render={({ field }) => (
            <FormItem className="flex flex-row items-center space-x-3 space-y-0">
              <FormControl>
                <Checkbox id="newsletterOptIn" checked={field.value} onCheckedChange={field.onChange} />
              </FormControl>
              <Label htmlFor="newsletterOptIn">{t("contact:newsletterOptIn")}</Label>
            </FormItem>
          )}
        />

        <div className="mt-4 flex space-x-4">
          <Button color="secondary" onClick={onBack} disabled={form.formState.isSubmitting} type="button">
            {t("contact:back")}
          </Button>

          <Button className="flex-1" type="submit" loading={form.formState.isSubmitting} disabled={form.formState.isSubmitting}>
            {t("contact:continueToPayment")}
          </Button>
        </div>
      </form>
    </Form>
  );
}
