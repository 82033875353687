import auth from "./auth.json";
import common from "./common.json";
import contact from "./contact.json";
import errors from "./errors.json";
import dates from "./dates.json";
import payment from "./payment.json";
import _return from "./return.json";
import summary from "./summary.json";
import validation from "./validation.json";
import duration from "./duration.json";

const locale = {
  dates,
  auth,
  contact,
  errors,
  common,
  validation,
  payment,
  summary,
  return: _return,
  duration,
};

const frLocale = locale; // Simplifies copy paste... every locale index is the same

export default frLocale;
