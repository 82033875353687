import { StorageUtils, keys } from "@/lib/utils/storage.utils";

export interface UtmParams {
  source?: string;
  medium?: string;
  campaign?: string;
  content?: string;
  term?: string;
}

export interface CamelCaseUtmParams {
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmContent?: string;
  utmTerm?: string;
}

export interface SnakeCaseUtmParams {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_term?: string;
}

class UtmUtils {
  static utmSaved: boolean = false;

  static saveUtmParams(utmParams: UtmParams) {
    if (typeof window === "undefined") return;

    if (utmParams.content)
      StorageUtils.setSessionItem(keys.CAPTURED_UTM_CONTENT, utmParams.content);

    if (utmParams.source)
      StorageUtils.setSessionItem(keys.CAPTURED_UTM_SOURCE, utmParams.source);

    if (utmParams.medium)
      StorageUtils.setSessionItem(keys.CAPTURED_UTM_MEDIUM, utmParams.medium);

    if (utmParams.campaign)
      StorageUtils.setSessionItem(
        keys.CAPTURED_UTM_CAMPAIGN,
        utmParams.campaign
      );

    if (utmParams.term)
      StorageUtils.setSessionItem(keys.CAPTURED_UTM_TERM, utmParams.term);

    this.utmSaved = true;
  }

  static getSavedUtmParams() {
    if (typeof window === "undefined") return {};

    let utm: UtmParams = {};

    const capturedUtmContent = StorageUtils.getSessionItem(
      keys.CAPTURED_UTM_CONTENT
    );

    const capturedUtmSource = StorageUtils.getSessionItem(
      keys.CAPTURED_UTM_SOURCE
    );

    const capturedUtmMedium = StorageUtils.getSessionItem(
      keys.CAPTURED_UTM_MEDIUM
    );

    const capturedUtmCampaign = StorageUtils.getSessionItem(
      keys.CAPTURED_UTM_CAMPAIGN
    );

    const capturedUtmTerm = StorageUtils.getSessionItem(keys.CAPTURED_UTM_TERM);

    if (capturedUtmContent) utm.content = capturedUtmContent;
    if (capturedUtmSource) utm.source = capturedUtmSource;
    if (capturedUtmMedium) utm.medium = capturedUtmMedium;
    if (capturedUtmCampaign) utm.campaign = capturedUtmCampaign;
    if (capturedUtmTerm) utm.term = capturedUtmTerm;

    return utm;
  }

  static convertUtmParamsToCamelCase(utm: UtmParams) {
    let camelCaseUtm: CamelCaseUtmParams = {};

    Object.entries(utm).forEach(([key, value]) => {
      camelCaseUtm[
        `utm${key[0].toUpperCase()}${key.slice(1)}` as keyof CamelCaseUtmParams
      ] = value;
    });

    return camelCaseUtm;
  }

  static convertUtmParamsToSnakeCase(utm: UtmParams) {
    let SnakeCaseUtm: SnakeCaseUtmParams = {};

    Object.entries(utm).forEach(([key, value]) => {
      SnakeCaseUtm[`utm_${key}` as keyof SnakeCaseUtmParams] = value;
    });

    return SnakeCaseUtm;
  }

  static appendUtmStringToUrl(url: string, customUtmParams?: UtmParams) {
    let utmParams: UtmParams = this.getSavedUtmParams();
    if (customUtmParams) utmParams = customUtmParams;

    // if everything is undefined, return the url as is...
    if (Object.values(utmParams || {}).every((value) => value === undefined))
      return url;

    function getPrefix(url: string) {
      return url.indexOf("?") === -1 ? "?" : "&";
    }

    // Otherwise append the utm params to the url
    let utmStr = url;

    utmStr += utmParams?.content
      ? `${getPrefix(utmStr)}utm_content=${utmParams.content}`
      : "";

    utmStr += utmParams?.source
      ? `${getPrefix(utmStr)}utm_source=${utmParams.source}`
      : "";

    utmStr += utmParams?.medium
      ? `${getPrefix(utmStr)}utm_medium=${utmParams.medium}`
      : "";

    utmStr += utmParams?.campaign
      ? `${getPrefix(utmStr)}utm_campaign=${utmParams.campaign}`
      : "";

    utmStr += utmParams?.term
      ? `${getPrefix(utmStr)}utm_term=${utmParams.term}`
      : "";

    return utmStr;
  }
}

export default UtmUtils;
