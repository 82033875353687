import { Button } from "@/components/ui/button";
import CodeInput from "@/components/ui/custom-form-ui/2fa/CodeInput";
import Heading from "@/components/ui/heading";
import { Separator } from "@/components/ui/separator";
import Spinner from "@/components/ui/spinner";
import { getOtp, loginWithOtp, useGetTwoFactorMethodsMutation } from "@/lib/services/auth.services";
import { HandledError, handleError } from "@/lib/services/helpers/clicknpark-errors.helpers";
import { TwoFactorMethod } from "@clicknpark/sdk";
import { CPEncryptedData } from "@clicknpark/sdk/dist/types/entities/common/encrypted-data/CPEncryptedData";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MaterialSymbol } from "react-material-symbols";

interface Props {
  email: string;
  onCancel: () => void;
  onSubmit: () => void;
  onSuccess: (authToken: string) => void;
  onError: (error: HandledError) => void;
}

export default function FormAuthOtp({ email, onCancel, onSubmit, onSuccess, onError }: Props) {
  const { t } = useTranslation("auth");

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<"methods" | "code">("methods");
  const [methods, setMethods] = useState<TwoFactorMethod[]>([]);

  const [encryptedData, setEncryptedData] = useState<CPEncryptedData | undefined>();

  const [selectedMethod, setSelectedMethod] = useState<TwoFactorMethod | undefined>();

  const twoFactorMethods = useGetTwoFactorMethodsMutation();

  useEffect(() => {
    (async () => {
      setLoading(true);

      try {
        const data = await twoFactorMethods.mutateAsync(email);
        setMethods(data);
      } catch (error) {
        onError(handleError(error));
      }

      setLoading(false);
    })();
  }, [email]);

  async function onMethodSelect(method: TwoFactorMethod) {
    setLoading(true);

    try {
      setSelectedMethod(method);
      const data = await getOtp({ email, method: method.type });
      setEncryptedData(data.encryptedData);
      setForm("code");
    } catch (error) {
      onError(handleError(error));
    }

    setLoading(false);
  }

  async function onCodeSubmit(code: string) {
    onSubmit();

    if (!selectedMethod) throw new Error("No selected method");
    if (!encryptedData) throw new Error("No encrypted data");

    setLoading(true);

    try {
      const { authToken } = await loginWithOtp({ email, code, encryptedData });
      onSuccess(authToken);
    } catch (error) {
      onError(handleError(error));
    }

    setLoading(false);
  }

  if (loading) {
    return (
      <div className="min-h-[100px] flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      {form === "methods" && (
        <>
          <Heading level="h4">{t("otpTitle")}</Heading>
          <p className="mt-1 mb-5 md:text-md text-sm">{t("otpText")}</p>

          <ul className="space-y-6">
            {methods.map((method) => (
              <li
                key={method.type}
                onClick={() => onMethodSelect(method)}
                className="flex items-center flex-1 p-3 border rounded-md cursor-pointer hover:bg-silver-900/50 hover:ring-2 ring-rapide-600 transition-all ease-in-out duration-150"
              >
                <div className="ml-1.5 flex items-center">
                  {method.type === "sms" ? (
                    <MaterialSymbol icon="phone" size={24} fill className="text-rapide-600" />
                  ) : (
                    <MaterialSymbol icon="email" size={24} fill className="text-rapide-600" />
                  )}
                </div>

                <div className="pl-4 ml-4 border-l border-silver-800">
                  <strong className="text-sm">{t(`method-${method.type}`)}: </strong>
                  <span className="text-gray-700 text-sm">{method.destination}</span>
                </div>
              </li>
            ))}
          </ul>

          <Button color="secondary" onClick={onCancel} className="mt-5" type="button">
            {t("cancel")}
          </Button>
        </>
      )}

      {form === "code" && (
        <>
          <Heading level="h3">
            {t("otpSent", {
              destination: selectedMethod?.destination,
            })}
          </Heading>
          <Separator className="my-5" />
          <CodeInput onSubmit={onCodeSubmit} />
          <div className="flex space-x-2">
            <Button type="button" onClick={() => setForm("methods")} className="mt-5" color="secondary" size="sm">
              {t("selectAnotherMethod")}
            </Button>
            <Button type="button" onClick={() => onMethodSelect(selectedMethod as TwoFactorMethod)} className="mt-5" color="secondary" size="sm">
              {t("sendAgain")}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
