import { ErrorBoundary } from "@/error";
import { configureSentry } from "@/lib/services/sentry.services";
import { keys } from "@/lib/utils/storage.utils";
import UtmUtils from "@/lib/utils/utm.utils";
import Checkout from "@/routes/Checkout";
import Return from "@/routes/Return";
import * as Sentry from "@sentry/react";
import Cookies from "js-cookie";
import posthog from "posthog-js";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, createBrowserRouter, useLocation, useSearchParams } from "react-router-dom";

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

configureSentry();

const authToken = Cookies.get(keys.AUTH_TOKEN);
const bifrostEmail = Cookies.get(keys.BIFROST_EMAIL);

function App() {
  const { t } = useTranslation("common");
  const location = useLocation();

  /* Capture pageview */
  useEffect(() => {
    posthog.capture("$pageview");
  }, [location]);

  /* Save UTM params */
  const [searchParams] = useSearchParams();
  const utm_source = searchParams.get("utm_source") || undefined;
  const utm_medium = searchParams.get("utm_medium") || undefined;
  const utm_campaign = searchParams.get("utm_campaign") || undefined;
  const utm_content = searchParams.get("utm_content") || undefined;
  const utm_term = searchParams.get("utm_term") || undefined;

  useEffect(() => {
    UtmUtils.saveUtmParams({
      source: utm_source,
      medium: utm_medium,
      campaign: utm_campaign,
      content: utm_content,
      term: utm_term,
    });
  }, [utm_source, utm_medium, utm_campaign, utm_content, utm_term]);

  if (bifrostEmail && authToken) {
    return (
      <div>
        <div className="bg-rapide-600 py-3 text-center text-sm font-semibold text-white">{t("loggedInAs", { email: bifrostEmail })}</div>
        <Outlet />
      </div>
    );
  }

  return <Outlet />;
}

export default sentryCreateBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Checkout />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/return/:reservationId/",
        element: <Return />,
        errorElement: <ErrorBoundary />,
      },
    ],
  },
]);
