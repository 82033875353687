import { AsYouType, CountryCode, parsePhoneNumber } from "libphonenumber-js";

export class StringFormattingUtils {
  public static toNoAccents(str: string): string {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  public static toNoSpecialChars(str: string): string {
    return str.replace(/[^a-zA-Z0-9]/g, "");
  }

  public static toNoSpaces(str: string): string {
    return str.replace(/\s/g, "");
  }
}

export class EmailFormattingUtils {
  public static toProtectedString(email?: string): string {
    if (!email) return "";
    email = email.replace(/(\w{1,3})(\w+)(@.*)/, "$1****$3");
    return email;
  }
}

export class PhoneFormattingUtils {
  public static formatPhoneNumber(
    value: string,
    countryCode: CountryCode = "CA"
  ): string {
    if (!value) return "";

    const formatter = new AsYouType(countryCode);
    const formattedNumber = formatter.input(value);

    if (formattedNumber.at(formattedNumber.length - 1)?.match(/\D/g)) {
      return formattedNumber.slice(0, formattedNumber.length - 1);
    }

    return formattedNumber;
  }

  public static getDisplayValue(
    phone?: string,
    countryCode: CountryCode = "CA"
  ) {
    if (!phone) return "";
    const phoneNumber = parsePhoneNumber(phone, countryCode);
    const nationalCountryCodes: CountryCode[] = ["CA", "US"];
    if (nationalCountryCodes.includes(countryCode))
      return phoneNumber.formatNational();
    return phoneNumber.formatInternational();
  }

  public static getE164Value(phone?: string, countryCode: CountryCode = "CA") {
    if (!phone) return "";
    const phoneNumber = parsePhoneNumber(phone, countryCode);
    return phoneNumber.format("E.164");
  }
}

export class NumberFormattingUtils {
  public static formatAmountWithoutCurrency(
    amount: number,
    language: string,
    currency: string
  ): string {
    return new Intl.NumberFormat(language, {
      style: "currency",
      currency: currency,
    }).format(amount);
  }

  public static formatAmountWithCurrency(
    amount: number,
    language: string,
    currency: string
  ): string {
    return new Intl.NumberFormat(language, {
      style: "currency",
      currency: currency,
      currencyDisplay: "narrowSymbol",
    }).format(amount);
  }
}