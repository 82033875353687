import { clicknpark } from "@/lib/services/config/api";
import { GetPaymentMethodsResponse } from "@clicknpark/sdk";
import { useQuery } from "@tanstack/react-query";

// Get logged in user
// ========================================

export const GET_PAYMENT_METHODS_QUERY_KEY = "get-payment-methods";

export const getPaymentMethods =
  async (): Promise<GetPaymentMethodsResponse> => {
    return await clicknpark.paymentMethods.getPaymentMethods();
  };

export const useGetPaymentMethodsQuery = ({
  enabled,
}: {
  enabled: boolean;
}) => {
  return useQuery<
    GetPaymentMethodsResponse,
    Error,
    GetPaymentMethodsResponse,
    [string]
  >({
    queryKey: [GET_PAYMENT_METHODS_QUERY_KEY],
    queryFn: getPaymentMethods,
    enabled: enabled,
    refetchOnWindowFocus: false,
  });
};
