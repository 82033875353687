import StepNumber from "@/components/checkout/StepNumber";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import Heading from "@/components/ui/heading";
import { useCheckoutStore } from "@/lib/store/checkout.store";
import { DateUtils } from "@/lib/utils/date.utils";
import { useTranslation } from "react-i18next";
import { MaterialSymbol } from "react-material-symbols";

interface Props {
  onBack: () => void;
}

export default function CheckoutDuration({ onBack }: Props) {
  const { t } = useTranslation("duration");
  const { getStart, getEnd } = useCheckoutStore();

  return (
    <Card>
      <div className="flex">
        <StepNumber number={1} completed={true} />
        <Heading level="h2" size="sm" className="ml-3" color="simple">
          {t("duration")}
        </Heading>
      </div>

      <div className="flex items-center justify-between gap-2 mt-3">
        <div className="flex items-center gap-1 md:justify-between">
          <p className="font-semibold text-sm md:text-base">{DateUtils.formatDate(getStart())}</p>
          <MaterialSymbol icon="arrow_forward" />
          <p className="font-semibold text-sm md:text-base">{DateUtils.formatDateRelative(getEnd(), { relativeTo: getStart() })}</p>
        </div>
        <Button variant="outline" size="sm" onClick={onBack}>
          {t("change")}
        </Button>
      </div>
    </Card>
  );
}
